@import 'site/styles/mediaBreakpoints';
@import "site/styles/mixins";

.discussions {
	display: flex;
	margin-top: 48px;
	margin-bottom: 48px;
	gap: 24px;

	& > *:nth-child(1) {
		width: 70%;
	}

	& > *:nth-child(2) {
		width: 30%;
	}

	.discussions-polls {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 24px;
	}

	.right-header {
		display: flex;
		align-items: center;
		margin-bottom: 24px;

		span {
			color: var(--primary-deep-blue, #1051A1);
			font-size: 12px;
			font-weight: 600;
			line-height: 140%;
			letter-spacing: 0.48px;
			text-transform: uppercase;
			white-space: nowrap;
			margin-right: 10px;
		}

		svg {
			margin-right: 10px;
		}

		.line {
			width: 100%;
			border-top: 1px solid #1051A1;;
		}
	}


}

.discussions-poll {
	display: flex;
	flex-direction: column;
	padding: 16px;
	border-radius: 16px;
	border: 1px solid var(--basic-grey-5, #e4eef2);
	height: 240px;
	color: var(--basic-grey-3, #a8afb8);
	font-size: 12px;
	font-weight: 400;
	line-height: 160%;

	.poll-text {
		color: var(--basic-black, #464a51);
		font-size: 18px;
		font-weight: 600;
		line-height: 140%;
		margin-bottom: 8px;
		overflow: hidden;
		height: 75px;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}

	.poll-data {
		display: flex;
		justify-content: space-between;
		margin-bottom: 16px;
	}

	.poll-site {
		margin-bottom: 16px;
	}

	.sp-btn {
		margin-top: auto;
		font-weight: 400;
	}
}

@mixin discussions-poll{
	border: none;
	background: transparent;
	@include flex-column;
	gap: var(--mobile-small-row-gap);
	height: max-content;

	& > div{
		margin: 0px;
	}

	.poll-text{
		height: max-content;
		@include h6-mobile;
	}

	.poll-data{

		span{
			@include p3-mobile;
		}
	}

	.poll-site{
		@include p3-mobile;
		margin-top: 8px;
	}

	button{
		height: max-content;
		padding: 12px 24px;
	}
}

/* Черный фон, белый текст */

html.color-inversion{
	.discussions-filters{
		.sp-dropdown{
			@include inversion-color-with-border;
		}
	}
	.discussions-poll{
		button{
			@include inversion-color-with-border;
		}
	}
	.button-show-more{
		@include inversion-color-with-border;
	}
}

@media(max-width: $mobile-width-breakpoint){
	.discussions{
		margin: 0px;
		padding: var(--mobile-wrapper-content-padding-all);
		display: flex;
		flex-direction: column-reverse;
		gap: var(--mobile-standart-row-gap);

		& > div{
			width: 100% !important;
		}

		.right-discussions{
			@include flex-column;
			gap: var(--mobile-small-row-gap);
			padding-bottom: 8px;
			border-bottom: 1px solid var(--deep-blue);

			.right-header{
				margin: 0px;
			}

			.discussions-poll{
				@include discussions-poll;
			}
		}

		.left-discussions{
			.discussions-polls{
				@include flex-column;
				gap: 10px;

				.discussions-poll{
					@include discussions-poll;
					border: 1px solid var(--grey-5);
				}
			}
		}

	}

	.wrapper-discussions-mobile-filters{
		padding: var(--mobile-wrapper-content-padding-all);
		@include flex-column;
		gap: var(--mobile-standart-row-gap);

		.wrapper-group-fields{
			padding: var(--mobile-wrapper-content-padding-all);
			background: var(--white);

			.d-flex{
				gap: var(--mobile-standart-row-gap);

				.checkbox-input{
					height: 20px;
					width: 20px;
					@include p1-mobile;
					color: var(--black);
				}
			}
		}

		.sort{

			& > span{
				display: none;
			}

			.sp-dropdown{
				width: 100% !important;
				margin: 0px;

				.filter{
					margin: 0px;
					width: 100%;
					@include flex-row;
					gap: var(--mobile-small-row-gap);
					flex-direction: row-reverse;
					justify-content: center;

					&::before{
						display: none;
					}

					.filter-content{
						margin: 0px;
						flex-grow: unset;
						flex-basis: auto;
						padding: 0px;

						.show-value{
							@include p1-mobile;
							margin: 0px;
							padding: 0px;
							justify-content: center;
						}
					}

					.filter-arrow{
						display: block;
						width: 0px;
						transform: none !important;

						&::before{
							content: '';
							display: block;
							width: 20px;
							height: 20px;
							background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='20' viewBox='0 0 21 20' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.41667 5C3.18655 5 3 5.18655 3 5.41667V6.25C3 6.48012 3.18655 6.66667 3.41667 6.66667H17.5833C17.8135 6.66667 18 6.48012 18 6.25V5.41667C18 5.18655 17.8135 5 17.5833 5H3.41667ZM5.91667 9.16667C5.68655 9.16667 5.5 9.35321 5.5 9.58333V10.4167C5.5 10.6468 5.68655 10.8333 5.91667 10.8333H15.0833C15.3135 10.8333 15.5 10.6468 15.5 10.4167V9.58333C15.5 9.35321 15.3135 9.16667 15.0833 9.16667H5.91667ZM8 13.75C8 13.5199 8.18655 13.3333 8.41667 13.3333H12.5833C12.8135 13.3333 13 13.5199 13 13.75V14.5833C13 14.8135 12.8135 15 12.5833 15H8.41667C8.18655 15 8 14.8135 8 14.5833V13.75Z' fill='%238291A4'/%3E%3C/svg%3E");
						}

						svg{
							display: none;
						}
					}
				}

				&.active{
					.filter{
						.filter-arrow{
							&:before{
								background: none;
							}
						}
					}
				}

			}
		}
	}
}