@import "site/styles/mixins";

.widget2 {
  //для показа, потом удалить
  position: absolute;
  top: 10px;
  z-index: 99;

  width: 384px;
  height: 500px;
  border-radius: 8px;
  border: 1px solid var(--translucent-white-60);
  background: var(--translucent-white-30);
  backdrop-filter: blur(12px);

  .photo-name-position {
    display: flex;
    align-items: center;
    padding: 16px 24px;
    color: var(--white);

    .photo {
      margin-right: 16px;
    }

    .name {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      padding-bottom: 8px;
    }

    .position {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }


  .text-container {
    padding: 16px 24px;

    .text {
      padding-top: 4px;
      height: 350px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      overflow-y: scroll;
      color: var(--white);
    }
  }


}

/* Черный фон, белый текст */
html.color-inversion{
  .widget2{
    @include inversion-color-with-border;
  }
}