@import 'site/styles/mediaBreakpoints';
@import "site/styles/mixins";

.news-lenta {
	.tags-counties {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.topics {
			width: 100%;
			display: flex;
			padding-right: 20px;

			.news-topics {
				padding-right: 16px;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 150%;
				color: var(--grey-1);
				cursor: pointer;
			}
		}

		.county-select {
			width: 103px;
			text-transform: none;
			border: none;
			background-color: var(--grey-6);
			border-radius: 16px;
			padding: 6px 6px 6px 12px;
			cursor: pointer;
		}
	}

	.more-logo {
		top: 390px;
		right: 135px;
	}

	.acrticles-item {
		display: flex;
		width: 100%;

		.articles {
			display: grid;
			flex-grow: 1;
			grid-template-columns: repeat(3, 1fr);
			grid-template-rows: auto;
			gap: 20px;

			.big-article {
				grid-column: 1 / 2 span;
			}
		}
	}

	.articles-list {
		display: flex;
		margin-bottom: 80px;

		.right {
			flex-basis: 285px;
			flex-shrink: 0;
			padding-left: 24px;

			.sp-tabs {
				.sp-tab {
					padding: 0;
					margin-right: 16px;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;

					&.active {
						color: var(--deep-blue);
					}
				}
			}
		}

		.news-page {
			width: 100%;

			.sp-tabs {
				.sp-tab {
					padding: 0;
					margin-right: 16px;
					font-weight: 400;

					&.active {
						color: var(--deep-blue);
						font-weight: 600;
					}
				}
			}
		}
	}

	.load-more {
		margin-top: 30px;
	}
}

/* Черный фон, белый текст */

html.color-inversion{
	.news-lenta{
		input{
			@include inversion-color-with-border;
		}
	}
}

@media(max-width: $mobile-width-breakpoint){
	.news-lenta{
		max-width: 100%;
		padding: var(--mobile-wrapper-content-padding-all);
		@include flex-column;
		gap: var(--mobile-standart-row-gap);

		.tab-bar{
			flex-direction: column-reverse;
			padding: 0px;
			gap: var(--mobile-standart-row-gap);

			.exchange-item{
				@include flex-column;
				gap: var(--mobile-small-row-gap);
				align-items: flex-end;

				.exchange-name{
					color: var(--grey-3);
				}

				.currencies{
					padding: 0px;
					@include flex-row;
					gap: var(--mobile-standart-row-gap);

					.currencies-item{
						padding: 0px;
						@include flex-row;
						gap: var(--mobile-small-row-gap);

						.course{
							@include flex-row;
							gap: var(--mobile-small-row-gap);

							p{
								padding: 0px;
								@include p1-mobile;
							}
						}
					}
				}
			}

			.items{
				max-width: 100%;
				@include scroll-horizontal;

				&::-webkit-scrollbar{
					height: 0px;
				}

				.link{

					&.active{
						border-color: var(--deep-blue);
					}

					span{
						@include h7-mobile;
					}
				}
			}
		}

		.tags-counties{
			padding: 0px;
			max-width: 100%;
			gap: var(--mobile-standart-row-gap);

			.topics{
				@include flex-row;
				gap: var(--mobile-standart-row-gap);
				flex-grow: 1;
				@include scroll-horizontal;

				.topics-list{
					@include flex-row;

					.topic{
						min-width: max-content;
					}
				}

				.news-topics{
					padding: 0px;
					@include p1-mobile;
					min-width: max-content;
				}
			}

			& > div:last-child{
				min-width: max-content;
			}

			.news-filters{
				.selectors{
					padding: 0px;
				}
			}

			.caret-left_right{
				display: none;
			}

		}

		.articles-list{
			flex-direction: column-reverse;
			gap: var(--mobile-standart-row-gap);
			margin-bottom: 0px;

			.right{
				padding: 0px;
				@include flex-column;
				gap: var(--mobile-standart-row-gap);
				flex-basis: max-content;

				.tabs{
					.sp-tabs-container{
						margin: 0px;
						height: max-content;

						.sp-tabs-well{
							height: max-content;
							@include flex-row;
							@include scroll-horizontal;
							max-width: 100%;
							gap: var(--mobile-standart-row-gap);

							.sp-tab{
								margin: 0px;
							}
						}
					}
				}

				.news-more{
					@include scroll-horizontal;
					max-width: 100%;

					.articles-grid{
						@include flex-row;
						gap: var(--mobile-standart-row-gap);

						a{
							min-width: 167px;
							max-width: 167px;
							margin: 0px;
							padding-bottom: 8px;
							@include flex-column;
							gap: var(--mobile-small-row-gap);

							.title{
								@include h7-mobile;
								word-wrap: break-word;
								overflow: hidden;
								text-overflow: ellipsis;
								display: -webkit-box;
								-webkit-line-clamp: 3;
								-webkit-box-orient: vertical;
								margin: 0px;
								flex-grow: 1;
							}

							.date{
								@include p3-mobile;
							}
						}

						a:last-child{
							border-bottom: 1px solid var(--grey-4);
						}
					}
				}
			}

			.news-page{
				@include flex-column;
				gap: var(--mobile-standart-row-gap);

				.sp-tabs{
					.sp-tabs-container{
						margin: 0px;
						height: max-content;

						.sp-tabs-well{
							height: max-content;
							@include flex-row;
							@include scroll-horizontal;
							max-width: 100%;
							gap: var(--mobile-standart-row-gap);

							.sp-tab{
								margin: 0px;
							}
						}
					}
				}

				.acrticles-item{

					.articles{
						@include flex-row;
						flex-wrap: wrap;
						gap: var(--mobile-standart-row-gap);
						max-width: 100%;

						.big-article{
							margin: 0px;

						}

						.mid-article{
							margin: 0px;
						}
					}
				}
			}
		}
	}
}
