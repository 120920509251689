@import 'site/styles/mediaBreakpoints';
@import "site/styles/mixins";

.npa-container {
	color: var(--black);
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding-bottom: 48px;

	.search-container {
		padding-top: 10px;

		.search-filter {
			display: none;
		}
	}



	.filters {
		display: flex;
		align-items: center;
		gap: 8px;
	}

	.main {
		display: flex;
		flex-direction: column;
		gap: 24px;
		padding-top: 24px;

		.current {
			display: flex;
			flex-direction: column;
			gap: 24px;

			.top {
				display: flex;
				justify-content: space-between;
				align-items: center;

				a {
					color: #1051a1;
					font-size: 14px;
					font-weight: 400;
					line-height: 21px;
					word-wrap: break-word;
					text-decoration: none;
				}
			}

			.cards {
				display: grid;
				gap: 16px;
				grid-template-columns: repeat(4, 1fr);

				.card-wrapper {
					cursor: pointer;
				}

				.current-card {
					padding: 16px;
					background-color: #f1f7fa;
					border-radius: 8px;
					display: flex;
					flex-direction: column;
					gap: 16px;

					.title {
						display: flex;
						gap: 8px;
						align-items: center;

						svg {
							width: 24px;
							height: 24px;
						}
					}

					h4 {
						color: var(--deep-blue);
					}
				}
			}
		}

		.other {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 24px;

			.list-block {
				display: flex;
				flex-direction: column;
				gap: 24px;

				button {
					border: none;
					border-radius: 24px;
					background: var(--grey-6);
					color: var(--grey-2);
					padding: 8px 12px;
				}
			}

			.list {
				display: flex;
				flex-direction: column;
				gap: 16px;

				.entry {
					padding: 16px;
					border-radius: 8px;
					border: 1px solid #e4eef2;
					background: #fff;
					display: flex;
					flex-direction: column;
					gap: 8px;
					min-height: 198px;

					.text {
						flex: 1;
					}

					.bottom {
						display: flex;
						justify-content: space-between;
						align-items: center;

						.date {
							display: flex;
							gap: 8px;
							align-items: center;

							.icon {
								width: 16px;
								height: 16px;
							}
						}

						a {
							text-decoration: underline;
							text-decoration-skip-ink: none;
						}
					}
				}
			}
		}
	}

	.projects {
		display: flex;
		flex-direction: column;
		gap: 24px;

		.entry {
			border-radius: 8px;
			border: 1px solid var(--grey-5);
			display: flex;
			gap: 24px;
			padding: 16px;

			.left {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 10px;
				width: 94px;

				.download {
					background-color: var(--grey-6);
					border-radius: 24px;
					padding: 8px 12px;
					border: none;
					display: flex;
					gap: 8px;
					align-items: center;
					color: var(--grey-1);
					cursor: pointer;
					text-decoration: none;

					img {
						width: 20px;
						height: 20px;
					}
				}

				p {
					color: var(--grey-2);
				}
			}

			.right {
				flex: 1;
				display: flex;
				flex-direction: column;
				gap: 8px;

				.info {
					display: flex;
					justify-content: space-between;
					align-items: center;
					gap: 16px;
				}

				.note {
					text-decoration: underline;
					text-decoration-skip-ink: none;
				}

				.date {
					color: var(--grey-2);
				}
			}
		}
	}
}

/* Черный фон, белый текст */

html.color-inversion{
	.npa-container{
		.current-card, input{
			@include inversion-color-with-border;
		}
	}
}

@media(max-width: $mobile-width-breakpoint){
	.npa-container{
		background: var(--white);
		gap: 0px;
		padding: 0px;

		.main{
			padding: var(--mobile-wrapper-content-padding-all);

			.current{

				.cards{
					@include flex-column;
					gap: var(--mobile-small-row-gap);

					.card-wrapper{
						h4{
							@include h4-mobile;
						}
					}
				}
			}
		}

		.npa-filters-mobile{
			padding: var(--mobile-wrapper-content-padding-all);

			.wrapper-date-picker{
				padding: var(--mobile-wrapper-content-padding-all);
				background: var(--white);

				.sp-trigger{
					min-width: 100%;
				}
			}
		}

		.filters{
			padding: var(--mobile-wrapper-content-padding-horizontal);
		}

		.projects{
			padding: var(--mobile-wrapper-content-padding-vertical);
			gap: var(--mobile-standart-row-gap);

			.entry{
				border-radius: 0px;
				border-left: none;
				border-right: none;
				flex-direction: column-reverse;

				.right{

					.info{
						@include flex-column;
						gap: 4px;
						align-items: flex-start;

						.p4{
							@include p3-mobile;
							color: var(--grey-2);
						}

						.p2{
							@include p2-mobile;
							color: var(--grey-2);
						}
					}

					.p2{
						@include p2-mobile;
					}
				}

				.left{
					width: 100%;

					a{
						width: 100%;
						justify-content: center;
					}
				}
			}
		}
	}
}
