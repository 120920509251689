@import 'site/styles/mediaBreakpoints';
@import "site/styles/mixins";

.open-data-page-isLoading {
	margin-top: 225px;
}

.open-datalist-container {
	.header-container {
		display: flex;
		position: fixed;
		top: 100px;
		z-index: 100;
		align-items: center;
		align-self: stretch;

		.header {
			padding-right: 24px;
		}
		.open-data-header {
			padding-top: 4px;
			color: var(--grey-2);
			font-weight: 400;
		}
	}

	.open-data-items {
		display: flex;
		margin-top: 24px;

		.sidebar {
			position: sticky;
			flex-basis: 285px;
			flex-shrink: 0;
			width: 285px;
			margin: 0 24px 30px 0;

			.sidebar-data-library {
				.head {
					background-color: var(--deep-blue);
					color: var(--white);
					padding: 8px 16px;
				}

				.tabs {
					position: relative;
					overflow-y: hidden;
					display: flex;
					flex-direction: column;
					height: 400px;
				}

				a {
					text-decoration: none;
				}

				.data-library-item {
					font-weight: 400;
					padding: 8px 16px;
					border-radius: 4px;
					width: 100%;

					a {
						color: var(--black);
					}

					&:hover {
						cursor: pointer;
						background-color: var(--grey-5);
						a {
							color: var(--deep-blue);
						}
					}
				}
				.active {
					color: var(--white);
					background-color: var(--deep-blue);
				}
			}

			.sidebar-data {
				display: flex;
				align-items: center;
				color: var(--deep-blue);
				text-transform: uppercase;

				.line {
					width: 100%;
					margin-left: 10px;
					border-bottom: 1px solid var(--deep-blue);
				}
			}

			.open-data-tags-container {
				display: flex;
				flex-wrap: wrap;
				padding-bottom: 24px;

				.tags {
					height: 25px;
					cursor: pointer;
					color: var(--black);
					word-wrap: break-word;
					opacity: 0.6;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 150%;
					text-decoration: underline;
					padding-right: 10px;
				}
			}
			.right {
				text-transform: lowercase;
				cursor: pointer;
			}
		}

		.open-data-records {
			width: 100%;
			margin-bottom: 24px;
		}
	}
}

.open-data-container {
	display: flex;
	width: 100%;
	margin: 16px 0;
	padding: 16px;
	align-items: flex-start;
	border-radius: 16px;
	border: 1px solid var(--grey-5);

	.mid-data-items {
		padding-left: 24px;
		width: 100%;

		.theme {
			color: var(--grey-3);
			font-weight: 500;
			padding-bottom: 8px;
		}

		.text {
			word-wrap: break-word;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			width: 100%;
			color: var(--black);
		}

		.initiator {
			padding-top: 8px;
			color: var(--grey-3);
			font-weight: 400;
		}

		.info {
			display: flex;
			.date {
				color: var(--grey-3);
				font-weight: 400;
			}

			:nth-child(-n + 2) {
				padding-right: 10px;
				padding-left: 10px;
				border-right: 1px solid var(--grey-3);
			}

			:nth-child(3) {
				padding-left: 10px;
			}

			:nth-child(1) {
				padding-left: 0;
			}
		}

		.statistic {
			display: flex;
			justify-content: flex-end;
			width: 100%;
			text-align: right;

			color: var(--grey-1);

			.views,
			.download,
			.comm,
			.like,
			.dislike {
				display: flex;
				align-items: center;

				img {
					padding: 0 8px;
					height: 20px;
				}
			}
		}
	}
}

.open-data-page {
	.open-data-breadcrumb {
		display: flex;

		.header-breadcrumb {
			color: var(--grey-3);
		}
	}

	.head,
	.rating-star {
		margin-bottom: 20px;
		color: var(--grey-2);
	}

	.brief {
		width: 100%;

		.brief-text {
			padding: 0 24px 32px 0;

			.theme {
				padding-bottom: 16px;
				color: var(--grey-2);
			}

			.text {
				color: var(--black);
				padding-bottom: 16px;
			}

			.initiator {
				color: var(--deep-blue);
				font-weight: 400;
			}
		}
	}

	.tags-wrapper {
		display: flex;
		justify-content: space-between;
		margin-bottom: 20px;

		.tags {
			display: flex;
			gap: 10px;
		}

		.statistic {
			display: flex;
			justify-content: flex-end;
			width: 100%;
			text-align: right;
			margin-bottom: 32px;
			color: var(--grey-1);

			.views,
			.download,
			.comm,
			.like,
			.dislike {
				display: flex;
				align-items: center;

				img {
					padding: 0 8px;
					height: 20px;
				}
			}
		}
	}

	.data:empty {
		display: none;
	}

	.data {
		display: flex;
		padding: 24px;
		flex-direction: column;
		align-items: flex-start;
		gap: 16px;
		align-self: stretch;
		margin-bottom: 24px;

		border-radius: 16px;
		background: var(--white, #fff);

		.data-item {
			display: flex;
			align-items: center;
			gap: 16px;
			align-self: stretch;

			.data-item-button {
				color: var(--grey-1);
				font-weight: 400;
			}

			.p2 {
				font-weight: 400;
			}

			.data-item-text {
				line-height: 1.2;

				.meta {
					color: var(--grey-2);
				}
			}
		}
	}

	.passport-table {
		color: var(--black);
		width: 100%;
		font-size: 14px;
		font-weight: 400;
		border-radius: 16px;
		border-collapse: collapse;
		overflow: hidden;
		-webkit-border-radius: 16px;
		-moz-border-radius: 16px;
		-khtml-border-radius: 16px;
		background-color: var(--white);
		gap: 2px;

		td {
			padding: 16px;
		}

		tr {
			border-bottom: 1px solid var(--light);
		}

		.table-header {
			border-bottom: 1px solid var(--light);
			background: var(--grey-5);
			border-radius: 16px;
		}

		.table-row {
		}
	}
}

/* Черный фон, белый текст */

html.color-inversion{
	.open-datalist-container{
		.data-library-item{
			border: 1px solid transparent;
		}
		.data-library-item.active, .data-library-item:hover{
			@include inversion-color-with-border;
		}
	}

	.open-data-records{
		input{
			@include inversion-color-with-border;
		}
	}

	.open-data-page{
		.wrapper-data-list .data, .data-item button{
			@include inversion-color-with-border;
		}
		.passport-table{
			background: black;

			.table-header{
				background: black;
			}
			td{
				color: white;
			}
		}
	}
}

@media(max-width: $mobile-width-breakpoint){
	.open-datalist-container{
		background: var(--white);
		.open-data-items{
			margin: 0px;
			flex-direction: column;
			gap: var(--mobile-standart-row-gap);

			.open-data-mobile-sidebar{
				padding: var(--mobile-wrapper-content-padding-horizontal);

				.sort{
					padding-top: 16px;

					& > span{
						display: none;
					}

					.sp-dropdown{
						width: 100% !important;
						margin: 0px;

						.filter{
							margin: 0px;
							width: 100%;
							@include flex-row;
							gap: var(--mobile-small-row-gap);
							flex-direction: row-reverse;
							justify-content: center;

							&::before{
								display: none;
							}

							.filter-content{
								margin: 0px;
								flex-grow: unset;
								flex-basis: auto;
								padding: 0px;

								.show-value{
									@include p1-mobile;
									margin: 0px;
									padding: 0px;
									justify-content: center;
								}
							}

							.filter-arrow{
								display: block;
								width: 0px;
								transform: none !important;

								&::before{
									content: '';
									display: block;
									width: 20px;
									height: 20px;
									background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='20' viewBox='0 0 21 20' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.41667 5C3.18655 5 3 5.18655 3 5.41667V6.25C3 6.48012 3.18655 6.66667 3.41667 6.66667H17.5833C17.8135 6.66667 18 6.48012 18 6.25V5.41667C18 5.18655 17.8135 5 17.5833 5H3.41667ZM5.91667 9.16667C5.68655 9.16667 5.5 9.35321 5.5 9.58333V10.4167C5.5 10.6468 5.68655 10.8333 5.91667 10.8333H15.0833C15.3135 10.8333 15.5 10.6468 15.5 10.4167V9.58333C15.5 9.35321 15.3135 9.16667 15.0833 9.16667H5.91667ZM8 13.75C8 13.5199 8.18655 13.3333 8.41667 13.3333H12.5833C12.8135 13.3333 13 13.5199 13 13.75V14.5833C13 14.8135 12.8135 15 12.5833 15H8.41667C8.18655 15 8 14.8135 8 14.5833V13.75Z' fill='%238291A4'/%3E%3C/svg%3E");
								}

								svg{
									display: none;
								}
							}
						}

						&.active{
							.filter{
								.filter-arrow{
									&:before{
										background: none;
									}
								}
							}
						}

					}
				}

				.wrapper-moving-panel{
					.wrapper-datepicker{
						padding: var(--mobile-wrapper-content-padding-all);
						background: var(--white);

						.sp-trigger{
							min-width: 100%;
						}
					}

					.sidebar-data-library{
						padding: var(--mobile-wrapper-content-padding-all);
						background: var(--white);
						@include flex-column;
						gap: var(--mobile-small-row-gap);

						.p2{
							&.active{
								color: var(--deep-blue);
							}
						}
					}
				}
			}

			.open-data-records{

				& > div{
					@include flex-column;

					.open-data-container{
						margin: 0px;
						border-radius: 0px;
						border-left: none;
						border-right: none;

						.images{
							display: none;
						}

						.mid-data-items{
							padding: 0px;
							@include flex-column;
							gap: 4px;

							.theme, .text, .initiator{
								padding: 0px;
							}

							.theme{
								@include p3-mobile;
							}

							.text{
								@include h7-mobile;
							}

							.initiator{
								@include p2-mobile;
							}

							.info{
								padding-top: 12px;
								@include flex-column;
								gap: 4px;

								.date{
									padding: 0px;
									border: none;
									@include p2-mobile;
								}
							}
						}
					}
				}
			}
		}
	}

	.open-data-page{
		margin: 0px !important;
		padding: var(--mobile-wrapper-content-padding-all) !important;
		@include flex-column;
		gap: var(--mobile-standart-row-gap);

		.wrapper-back{
			@include flex-row;
			align-items: center;
			gap: var(--mobile-small-row-gap);
			color: var(--grey-3);
		}

		.open-data-breadcrumb{
			display: none;
		}

		.tags-wrapper{
			margin: 0px;
			flex-direction: column;
			gap: var(--mobile-standart-row-gap);

			.tags{
				flex-wrap: wrap;
				.p2{
					opacity: .6;
				}
			}

			.rating-wrapper{
				flex-wrap: wrap;
			}
		}

		.brief{

			.brief-text{
				padding: 0px;
				@include flex-column;
				gap: var(--mobile-small-row-gap);

				& > div{
					padding: 0px !important;
				}

				.theme{
					@include p2-mobile;
				}

				.text{
					@include h4-mobile;
				}

				.initiator{
					@include p2-mobile;
				}
			}
		}

		.wrapper-data-list, .passport{
			@include flex-column;
			gap: var(--mobile-small-row-gap);

			& > .p4{
				margin: 0px;
			}
		}

		.data{
			margin: 0px -16px;
			border-radius: 0px;

			.data-item{

				button{
					padding: 8px 12px;
					height: max-content;
				}

				.data-item-text{

					.p2{
						@include p2-mobile;
					}
				}
			}
		}

		.passport{

			& > div{
				@include scroll-horizontal;

				tr{

					td{
						@include p2-mobile;
						padding: 8px 16px;
					}

					& > td:nth-child(2), & > td:last-child{
						min-width: 280px;
					}
				}
			}
		}

	}
}
