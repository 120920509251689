.portals-map-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.25rem;

  .portals-map-item-title {
    font-family: "Golos Text", sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
    margin-bottom: 5px;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      cursor: pointer;
      transition: 0.2s ease-out;
      color: #2fa8ff;
    }
  }

  .portals-map-item-content-children {
    .portals-map-item-title {
      font-weight: 400;
    }
  }

  .portals-map-item-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .portals-map-item-content-item {
      font-family: "Golos Text", sans-serif;
      font-weight: 400;
      font-size: 14px;
      color: #fff;
      cursor: pointer;
      text-decoration: none;

      &:hover {
        cursor: pointer;
        transition: 0.2s ease-out;
        color: #2fa8ff;
      }
    }
  }

  .wrapper-submenu{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-left: 0.75rem;

    .show-all-submenu-items{
      color: rgba(255, 255, 255, 0.3);
      cursor: pointer;
      display: flex;
      align-items: center;

      svg{
        opacity: .3;
      }

      &.active{

        svg{
          transform: rotate(180deg);
        }
      }
    }
  }
}


.footer {
  .show-all-submenu-items {
    padding-top: 5px;
  }

  .show-all-submenu-items {
    color: rgba(255, 255, 255, 0.9) !important;
    svg{
      opacity: .9 !important;
    }
  }
}