@import 'site/styles/mediaBreakpoints';

footer {
	background: var(--basic-black, #464a51);
	color: var(--white);
	padding-top: 48px;

	.footer {
		margin: 0 auto;
		max-width: var(--site-width);
		width: 100%;
		padding-bottom: 48px;
		a {
			text-decoration: none;
		}

		.social {
			display: inline-block;
			margin: 0 20px;
		}

		&.footer-phone {
			width: 100%;
			padding: 0 15px;

			.portal-sakhagov {
				display: flex;
				align-items: center;
			}

			.footer-social {
				margin: 20px 0;
				display: flex;
				justify-content: center;
			}
		}

		.footer-top {
			height: 36px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 24px;

			.portal-sakhagov {
				display: flex;
				align-items: center;
				width: 100%;

				.footer-site-name {
					color: var(--basic-white);
					word-wrap: break-word;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}
			}
		}

		.border {
			border: 0.5px solid rgba(255, 255, 255, 0.3);
			margin-bottom: 24px;
		}

		.footer-menu {
			display: flex;
			flex-direction: row;
			margin-top: 24px;
			margin-bottom: 24px;
			align-items: flex-start;
			font-size: 16px;

			.all-portals-map {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				padding-left: 20px;
				width: 100%;

				.portal-block-header {
					font-family: 'Golos Text', sans-serif;
					font-weight: 600;
					font-size: 12px;
					color: var(--translucent-white-30);
					text-transform: uppercase;
					//margin-bottom: 16px;
				}

				.portals-map-container {
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					margin-top: 24px;
					overflow-x: hidden;
					overflow-y: auto;
					width: 100%;

					.portals-map-column {
						display: flex;
						flex-direction: column;
						padding-right: 10px;
						width: 25%;

						.portals-list-item-wrapper {
							display: flex;
							align-items: center;

							.portals-list-item-image {
								display: flex;
								align-items: center;
								justify-content: center;
								width: 20px;
								flex-shrink: 0;
							}

							.portals-list-item {
								display: flex;
								justify-content: flex-start;
								font-family: 'Golos Text', sans-serif;
								font-weight: 400;
								font-size: 14px;
								color: #fff;
								text-decoration: none;
								cursor: pointer;

								.portals-list-item-text {
									//margin-left: 10px;
								}

								&:hover {
									cursor: pointer;
									transition: 0.2s ease-out;
									color: #2fa8ff;
								}
							}
						}
					}
				}
			}

			.portals-list-container {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				width: 25%;

				.portals-list-title {
					font-family: 'Golos Text', sans-serif;
					font-weight: 600;
					font-size: 12px;
					color: var(--translucent-white-30);
					text-transform: uppercase;
					margin-bottom: 16px;
				}

				.portals-list-item {
					display: flex;
					font-family: 'Golos Text', sans-serif;
					font-weight: 400;
					font-size: 14px;
					margin-bottom: 16px !important;
					color: #fff;
					text-decoration: none;
					cursor: pointer;
					.portals-map-item-title {
						margin-left: 10px;
					}

					.portals-list-item-image {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 20px;
					}
					&:hover {
						cursor: pointer;
						transition: 0.2s ease-out;
						color: #2fa8ff;
					}
				}
			}
		}

		.footer-social {
			//justify-content: center;
			margin-bottom: 20px;
		}

		.footer-bottom {
			margin: 20px 0;
			display: flex;
			justify-content: space-between;
		}

		.copyright {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 20px;
			text-align: center;
			line-height: 1.3em;
			font-weight: 400;
			font-size: 12px;

			.footer-bottom-buttons {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: flex-start;
				gap: 24px;
			}

			.policy-agreement {
				a {
					color: var(--white);
					padding: 0 24px;
					border-right: 0.5px solid rgba(255, 255, 255, 0.3);

					&:last-child {
						border-right: none;
					}
				}
			}
		}
	}
}

/* Черный фон, белый текст */

html.color-inversion{
	footer{
		.footer-bottom-buttons{
			button{
				background: black;
			}
		}
	}
}

@media(max-width: $tabled-width-breakpoint){
	footer{
		.footer-top{
			padding: 0px 16px;
			.social{
				margin: 0px;
			}
		}
	}
}

@media(max-width: 854px){
	footer{
		.footer{
			.footer-menu{
				flex-direction: column;
				padding: 0px 16px;

				.all-portals-map{
					padding: 0px;
				}
			}

			.copyright{
				flex-wrap: wrap;
				gap: 16px;
				justify-content: center;

				.footer-bottom-buttons{
					width: 100%;
					justify-content: center;
				}
			}
		}
	}
}

@media (max-width: $mobile-width-breakpoint) {
	footer {
		padding: var(--mobile-section-padding);

		.footer {
			max-width: 100%;
			padding: 0px !important;
			@include flex-column;
			gap: var(--mobile-standart-row-gap);

			.footer-top {
				margin: 0px;
				flex-direction: column;
				height: max-content;
				align-items: flex-start;
				gap: var(--mobile-medium-row-gap);

				.social {
					margin: 0px;

					.social-block {
						gap: var(--mobile-medium-row-gap);

						a {
							margin: 0px;
						}
					}
				}
			}

			.border {
				margin: 0px;
				border-width: 0px;
				border-bottom-width: 1px;
			}

			.footer-menu {
				margin: 0px;
				@include flex-column;
				gap: var(--mobile-medium-row-gap);

				.all-portals-map {
					padding: 0px;
					width: 100%;

					.portals-map-container {
						width: 100%;
						@include flex-column;
						gap: var(--mobile-standart-row-gap);

						.portals-map-column {
							width: 100%;
							@include flex-row;
							flex-wrap: wrap;
							width: 100%;
							gap: var(--mobile-standart-row-gap);

							.portals-map-item {
								width: calc((100% - var(--mobile-standart-row-gap)) / 2);
								margin: 0px;

								.portals-map-item-title {
									@include p2-mobile-bold;
								}

								.portals-list-item-image {
									width: 18px;
									height: 18px;
								}
							}
						}
					}
				}

				.portals-map-container {
					width: 100%;
					@include flex-column;
					gap: var(--mobile-standart-row-gap);

					.portals-map-title {
						margin-bottom: 0px;
					}

					.portals-map-item-container {
						@include flex-row;
						flex-wrap: wrap;
						width: 100%;
						gap: var(--mobile-standart-row-gap);

						.portals-map-item {
							width: calc((100% - var(--mobile-standart-row-gap)) / 2);
							margin: 0px;

							.portals-map-item-title {
								@include p2-mobile-bold;
							}

							.portals-list-item-image {
								width: 18px;
								height: 18px;
							}
						}
					}
				}

				.portals-list-container {
					@include flex-row;
					flex-wrap: wrap;
					width: 100%;
					gap: var(--mobile-standart-row-gap);

					.portals-list-title {
						margin-bottom: 0px;
						width: 100%;
					}

					.portals-list-item-container {
						width: calc((100% - var(--mobile-standart-row-gap)) / 2);
						margin: 0px;

						.portals-list-item {
							margin: 0px !important;

							.portals-map-item-title {
								@include p2-mobile-bold;
							}

							.portals-list-item-image {
								width: 18px;
								height: 18px;
							}
						}
					}
				}
			}

			.copyright {
				margin: 0px;
				@include flex-column;
				gap: var(--mobile-standart-row-gap);
				align-items: flex-start;

				.footer-bottom-buttons {
					@include flex-column;
					min-width: 100%;
					gap: var(--mobile-standart-row-gap);

					a {
						width: 100%;
					}

					button {
						width: 100%;
						@include flex-row;
						justify-content: center;
						height: max-content;
						padding: 6px 12px;

						.icon-content {
							@include p1-mobile;
						}
					}
				}

				.policy-agreement {
					@include flex-column;
					gap: var(--mobile-small-row-gap);
					align-items: flex-start;
					margin-top: -8px;

					a {
						padding: 0px;
						border: 0px !important;
					}
				}
			}
		}
	}
}
