$mobile-width-breakpoint: 640px;

.inner-page-content:has(.structure-content){
	display: flex;
	flex-direction: column;
}

.structure-content{
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.top-structure-content{
		flex-grow: 1;
	}

	.widget-structure {
		width: 100%;
		max-width: calc(100% - 40px);
		margin: 0px auto 64px auto;
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;

		.controls-zoom {
			position: absolute;
			right: 16px;
			top: 0px;
			display: flex;
			flex-direction: column;
			gap: 10px;

			button {
				width: 28px;
				height: 28px;
				cursor: pointer;
				display: block;
				margin: 1px;
				font-weight: bold;
				line-height: 0.4em;
				background-color: var(--grey-4);
				border: none;
				border-radius: 2px;
			}
		}

		.wrapper-tree {
			padding: 0px 20px;
			min-width: max-content;
			position: absolute;
			cursor: move;

			.wrapper-item-structure {
				display: flex;
				flex-direction: column;
				gap: 24px;
				position: relative;
				min-width: 340px;

				&:after {
					content: '';
					width: 2px;
					height: calc(100% + 24px);
					position: absolute;
					left: -20px;
					top: -24px;
				}

				&:last-child {
					&:after {
						height: 0px;
					}
					& > .info {
						&:after {
							content: '';
							width: 2px;
							height: calc(50% + 26px);
							position: absolute;
							left: -22px;
							top: -26px;
						}
					}
				}

				&:only-child {
					&:after {
						display: none;
					}

					.info {
						&:after {
							content: '';
							height: calc(50% + 26px);
							width: 2px;
							position: absolute;
							top: -26px;
							left: -22px;
						}
					}
				}

				.info {
					display: flex;
					flex-direction: column;
					gap: 8px;
					box-sizing: border-box;
					border: 2px solid rgb(157, 209, 240);
					border-radius: 8px;
					padding: 16px;
					box-sizing: border-box;
					position: relative;

					&:before {
						content: '';
						width: 20px;
						height: 2px;
						position: absolute;
						top: calc((100% - 2px) / 2);
						left: -20px;
					}

					span {
						text-align: center;
					}

					.desc-item-structure {
						font-size: 12px;
					}

					a.head-item-structure {
						text-decoration: underline;
						text-align: center;
						cursor: pointer;
					}
				}

				.wrapper-children {
					display: flex;
					flex-direction: column;
					gap: 24px;
					width: calc(100% - 70px);
					margin-left: 70px;

					.wrapper-item-structure {
						min-width: unset;
					}
				}
			}

			.structure-level-1 {
				display: flex;
				justify-content: center;
				position: relative;

				&:after {
					content: '';
					width: 2px;
					height: 20px;
					position: absolute;
					top: 100%;
					left: calc((100% - 2px) / 2);
				}

				& > .wrapper-item-structure {
					&:after {
						display: none;
					}

					.info {
						&:before,
						&:after {
							display: none;
						}
					}
				}
			}

			.structure-level-2 {
				padding-top: 20px;
				display: flex;
				position: relative;
				max-width: max-content;
				margin: 20px auto 0px auto;
				gap: 26px;
				align-items: flex-start;

				.col-structure {
					display: flex;
					flex-direction: column;
					gap: 24px;
					position: relative;
					max-width: 350px;
					position: relative;

					&:before {
						content: '';
						height: 20px;
						width: 2px;
						position: absolute;
						top: -20px;
						left: calc((100% - 2px) / 2);
					}

					&.with-children {
						&:only-child {
							& > .wrapper-item-structure:first-child {
								& > .info {
									&:after,
									&:before {
										display: none;
									}
								}
							}
						}
					}
				}

				&.horizontal-branch {
					.col-structure {
						&:after {
							content: '';
							width: calc(100% + 26px);
							height: 2px;
							position: absolute;
							top: -20px;
							left: -13px;
						}

						&.without-children {
							&:after {
								width: calc(100% + 33px);
								left: -20px;
							}

							&:before {
								display: none;
							}

							&:only-child {
								&:after {
									width: calc(50% + 20px);
								}
							}

							& > .wrapper-item-structure {
								&:first-child {
									&:after {
										height: calc(100% + 20px);
										top: -20px;
									}
									&:only-child {
										.info {
											&:after {
												height: calc(50% + 22px);
												top: -22px;
											}
										}
									}
								}
							}
						}

						&.with-children {
							&:last-child {
								&:after {
									width: calc(50% + 13px);
									left: -13px;
								}
							}

							&:first-child {
								&:after {
									width: calc(50% + 13px);
									left: 50%;
								}
							}

							& > .wrapper-item-structure {
								&:after {
									display: none;
								}
								& > .info {
									&:before,
									&:after {
										display: none;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.structure-text-with-documents {
		margin-bottom: 64px;
	}

	.wrapper-image-structure {
		margin-bottom: 64px;
		img {
			max-width: 100%;
			margin: 0px auto;
		}
	}

}

@media (max-width: $mobile-width-breakpoint) {
	.widget-structure {
		max-width: 70%;
		max-height: 300px;
		margin: 24px auto;
	}
	.wrapper-image-structure {
		margin: 0px;
		padding: var(--mobile-section-padding);
	}
}
