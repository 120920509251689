@import 'site/styles/mediaBreakpoints';
@import "client/styles/mixins";

.widget-quick-access-bar {
  position: absolute;
  top: 687px;
  left:50%;
  transform:translate(-50%, -50%);
  z-index: 99;
  min-width: 1200px;
  display: flex;
  height: 175px;
  border-radius: 8px;
  border: 1px solid var(--light);
  overflow: hidden;

  .user-widget-container {
    .icon-user-widget-container {
      height: 48px;
      margin-bottom: 8px;


    .empty {
      width: 48px;
      height: 48px;
      background: #fff;
      border: 1px solid var(--grey-4);
      border-radius: 100px;
      display: flex;
      align-items: center;
      text-align: center;
      overflow: hidden;
      justify-content: center;
      font-size: 10px;
      color: #888;
    }
    }
  }

  .appeal-container,
  .vacancy-container,
  .gis-map-container,
  .news-container,
  .sites-container,
  .broadcast-container,
  .user-widget-container {
    margin: 0;
    width: auto;
    height: 175px;
    padding: 16px;
    border-right: 1px solid var(--light);
    background: var(--white);
    flex: 1;

    &:nth-last-child(1) {
      border: none;
    }
  }

  .number-request,
  .number-vacancy,
  .number-sites {
    width: 168px;
    height: 48px;
    margin-bottom: 8px;
    color: var(--deep-blue);
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
  }

  .other-button {
    font-weight: 400;

    .icon-content svg {
      margin-top: 0;
    }
    &:hover {
      background: var(--light-hover);
    }
  }

  .appeal-button {
    font-weight: 400;

    &:hover {
      background: var(--deep-blue-hover);
    }
  }

  .icon-gis-map,
  .icon-news {
    width: 48px;
    height: 48px;
    margin-bottom: 8px;
  }

  .description {
    width: 168px;
    height: 46px;
    margin-bottom: 8px;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: var(--grey-1);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  .broadcast-date {
    width: 168px;
    height: 48px;
    margin-bottom: 8px;
    color: var(--deep-blue);
    font-size: 22px;
    white-space: nowrap;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
  }

  a {
    text-decoration: none;

    .broadcast-button {
      display: flex;
      width: 122px;

      .external-link {
        margin-left: 8px;
        width: 20px;
        height: 20px;
      }
    }
  }
}

/* Черный фон, белый текст */

html.color-inversion{
  .widget-quick-access-bar{
    & > div{
      background: black;
    }

    button{
      @include inversion-color-with-border;
    }
  }

  .submit-search-input input, .submit-search-input button, .bad-vision{
    @include inversion-color-with-border;
  }

}

@media(max-width: $mobile-width-breakpoint){
  .widget-quick-access-bar{
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    min-width: initial;
    max-width: 100%;
    overflow-x: auto;

    &::-webkit-scrollbar{
      height: 0px;
    }
    .appeal-container, .vacancy-container, .gis-map-container, .news-container, .sites-container, .user-widget-container{
      max-width: 140px;
      min-width: 140px;
      display: flex;
      flex-direction: column;
      gap: var(--mobile-small-row-gap)
    }

    .number-request, .number-vacancy, .number-sites{
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      height: auto;
      line-height: 36px;
      margin: 0px;
    }

    .description{
      width: auto;
      height: auto;
      margin: 0px;
      @include p2-mobile;
      font-size: 12px;
    }

    .wrapper-button{
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    .icon-gis-map, .icon-news, .icon-user-widget{
      height: 36px !important;
      width: 36px !important;
    }

    .icon-user-widget-container{
      height: max-content !important;
      margin: 0px !important;
    }
  }
}
