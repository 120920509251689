@import 'site/styles/mediaBreakpoints';

.big-article,
.mid-article {
	display: inline-block;
	vertical-align: top;
	height: 400px;
	text-decoration: none;
	transition: all 250ms;

	.no-image {
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

    &:hover {
        .title {
            color: var(--deep-blue);
        }

        .text, .text-middle {
            color: var(--black);
        }
	}
}

.big-article,
.mid-article,
.small-article {
	.info {
		display: flex;


		.date {
			flex-shrink: 0;
			padding-right: 10px;
		}

		.article-info {
			flex-grow: 1;
			text-align: right;
			display: flex;
			justify-content: flex-end;

			svg {
				opacity: 0.5;
			}
		}
	}

}

.big-article {
	width: 100%;

	&:hover{
		img{
			transform: none;
		}
	}

	.content {
		height: 415px;
		overflow: hidden;
		position: relative;

		.image {
			height: 284px;
			overflow: hidden;
			border-radius: 8px;

			img {
				width: 100%;
				height: 284px;
				object-fit: cover;
				border-radius: 8px;
			}
		}

		.title {
			width: 100%;
			left: 0;
			right: 50px;
			bottom: 50px;
			padding-top: 16px;
			z-index: 20;
			color: var(--black);
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			overflow-wrap: break-word;
			word-wrap: break-word;
			word-break: break-word;
			hyphens: auto;

			&:hover {
				color: var(--deep-blue);
			}
		}

		.text {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			color: var(--grey-2);
			margin-top: 8px;
		}

		.info {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			height: 50px;
			padding-top: 20px;
			z-index: 20;
			color: var(--grey-2);
			display: flex;
		}
	}
    &:hover {

        .title {
            color: var(--deep-blue);
        }

        .text {
            color: var(--black);
        }
	}
}

.mid-article {
	height: 400px;
	overflow: hidden;
	position: relative;
	min-width: 285px;

	&:last-child {
		margin-right: 0;
	}

	.image {
		height: 205px;
		overflow: hidden;
		border-radius: 8px;

		img {
			width: 100%;
			height: 205px;
			object-fit: cover;
			border-radius: 8px;
		}
	}

	.title {
		height: auto;
		padding-top: 10px;
		overflow: hidden;
		color: var(--black);
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		text-overflow: ellipsis;
	}

	.text-middle {
		word-wrap: break-word;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		color: var(--grey-2);
		margin-top: 8px;
	}

	.info {
		position: absolute;
		bottom: 0;
		margin-top: 20px;
		z-index: 20;
		color: var(--grey-2);

		.date {
			max-width: 200px;
		}
	}
}

.small-article {
	display: block;
	padding-bottom: 20px;
	margin-bottom: 20px;
	border-bottom: 1px solid var(--grey-4);
	text-decoration: none;

	&:last-child {
		margin-bottom: 0;
		border: 0;
	}

	.title {
		line-height: 1.3em;
		color: var(--black);
		margin-bottom: 5px;
	}

    .date {
        color: var(--grey-3);
    }

    &:hover {
        .title {
            color: var(--grey-1);
        }
    }
}

.articles{
	.image{
		img{
			transition: all linear 0.5s;
			&:hover{
				transform: scale(1.1);
			}
		}
	}
}

@media (min-width: 768px) and (max-width: 1199px) {
	.big-article {
		.content {
			img {
				width: 100%;
				height: 338px;
				object-fit: cover;
			}
		}
	}

	.mid-article {
		.image {
			img {
				width: 100%;
				height: 168px;
				object-fit: cover;
			}
		}
	}
}

@media (max-width: 767px) {
	.big-article {
		width: 100%;
		margin-bottom: 15px;
		margin-right: 0;
		height: auto;

		.content {
			width: 100%;
			height: auto;
			padding-bottom: 75%;
			overflow: hidden;
			position: relative;
			img {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
			.no-image {
				position: absolute;
				top: 0%;
				width: 100%;
				height: 100%;
				background: #888;
				color: #ccc;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}
	.mid-article {
		width: 100%;
		height: auto;
		background: #f5f5f5;
		margin-bottom: 15px;
		margin-right: 0;

		.county {
			display: flex;
			justify-content: space-between;
		}

		.image {
			width: 100%;
			padding-bottom: 75%;
			overflow: hidden;
			position: relative;
			img {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
			.no-image {
				position: absolute;
				top: 0%;
				width: 100%;
				height: 100%;
				background: #888;
				color: #ccc;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}

		.info {
			height: 30px;
			line-height: 30px;
			padding: 0 20px;
			z-index: 20;
			color: rgba(#000, 0.6);
			font-size: 13px;
			display: flex;

			.date {
				max-width: none;
			}
		}
	}
}

@media(max-width: $mobile-width-breakpoint){
	.big-article{
		margin: 0px;

		.content{
			padding: 0px;
			display: flex;
			flex-direction: column;
			gap: var(--mobile-small-row-gap);

			.text, .info{
				margin: 0px;
				padding: 0px;
			}

			.info{
				position: relative;
				height: max-content;

				.p3{
					@include p3-mobile;
				}
			}

			.title{
				@include h6-mobile;
				-webkit-line-clamp: 3;
				padding: 0px;
			}

			.text{
				@include p2-mobile;
			}

			.image{
				height: 231px;
				img{
					height: 231px;
				}
			}
		}
	}

	.mid-article{
		display: flex;
		flex-direction: row;
		gap: var(--mobile-standart-row-gap);
		background: none;

		.image{
			min-width: 80px;
			padding: 0px;
			height: max-content;
			max-width: 80px;

			picture{
				position: relative;
				min-width: 100%;
				max-width: 80px;

				img{
					position: relative !important;
					min-width: 80px;
					min-height: 80px;
					max-width: 80px;
				}
			}
		}

		.wrapper-content{
			display: flex;
			flex-direction: column;
			gap: 4px;

			.title, .text-middle , .info{
				padding: 0px;
				margin: 0px;
			}

			.info{
				position: relative;
				top: 0;
				height: max-content;

				.p3{
					@include p3-mobile;
					line-height: 140%;
				}
			}

			.title{
				@include h6-mobile;
				-webkit-line-clamp: 3;
			}

			.text-middle{
				@include p2-mobile;
			}
		}
	}
}
