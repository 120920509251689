@import "site/styles/mixins";
@import "site/styles/mediaBreakpoints";

.news-filters {
	display: flex;
	gap: 24px;
	justify-content: flex-start;
	margin-bottom: 32px;

	.sort {
		.p2 {
			color: var(--black);
			font-weight: 400;
		}
	}
}

@media(max-width: $tabled-width-breakpoint){
	.news-filters {
		flex-direction: column;

		.sp-dropdown{
			width: 100%;
		}
	}
}

/* Черный фон, белый текст */

html.color-inversion{
	.news-filters{
		input{
			@include inversion-color-with-border;
		}
	}
}
