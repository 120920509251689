@import "client/styles/mixins";
@import 'site/styles/mediaBreakpoints';

.all-sites-container {
  display: flex;
  max-width: 1200px;
  width: 100%;

  .all-sites {
    min-width: calc(100% - 286px);

    .content {
      display: flex;

      flex-direction: column;
      gap: 32px;
      .main-sites {
        display: flex;
        flex-direction: row;
        gap: 24px;
        margin: 32px 0;
        height: 68px;
      }
      .link-block {
        display: flex;
        gap: 24px;
        align-items: center;
        .name {
          font-size: 24px;
          font-weight: bold;
          line-height: 32px;
          color: var(--black);
        }
        .link {
          font-weight: 400;
          font-size: 16px;
          color: var(--translucent-black-20);
        }
      }

      .list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 24px;
      }
    }
  }

  .popular-sites {
    width: 283px;

    .content {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
}

/* Черный фон, белый текст */
html.color-inversion{
  .secondary-layout:has(.all-sites-container){
    background: black;

    .site-card{
      @include inversion-color-with-border;
    }
  }
}

@media(max-width: $mobile-width-breakpoint){
  .mountains{
    padding: var(--mobile-section-padding);

    .all-sites-container{
      max--width: 100%;
      flex-direction: column;
      gap: var(--mobile-standart-row-gap);

      .all-sites{
        margin: 0px;
        display: flex;
        flex-direction: column;
        gap: var(--mobile-standart-row-gap);

        .section-card__title{
          margin: 0px;
        }

        .content{
          display: flex;
          flex-direction: column;
          gap: var(--mobile-standart-row-gap);

          .main-sites{
            margin: 0px;
            height: max-content;
            gap: var(--mobile-small-row-gap);
            max-width: 100%;
            overflow-x: auto;

            &::-webkit-scrollbar{
              height: 0px;
            }

            .site-card{
              padding: 8px 16px;
              flex-direction: column;
              align-items: flex-start;
              gap: var(--mobile-small-row-gap);
              min-width: 168px;

              img{
                height: 24px;
                width: 24px;
              }

              .name{
                @include p2-mobile;
                flex-grow: 1;
              }
            }
          }

          .link-block{
            flex-direction: column;
            gap: var(--mobile-small-row-gap);
            align-items: flex-start;

            .name{
              @include h4-mobile;
            }

            .link{
              @include p1-mobile;
            }
          }

          .submit-search-input{

            input{
              padding: 8px 16px;
              @include p1-mobile;
              height: max-content;
            }

          }

          .sp-tabs-container{
            margin: 0px;

            &::-webkit-scrollbar{
              height: 0px;
            }

            .sp-tabs-well{
              height: max-content;
            }
          }

          .list{
            display: flex;
            flex-direction: column;
            gap: var(--mobile-small-row-gap);

            .site-card{
              padding: 8px;
              gap: var(--mobile-small-row-gap);

              img{
                width: 24px;
                height: 24px;
              }

              .name{
                @include p2-mobile;
              }
            }
          }
        }
      }

      .popular-sites{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: var(--mobile-standart-row-gap);

        .section-card__title{
          margin: 0px;
        }

        .content{
          flex-direction: row;
          max-width: 100%;
          overflow-x: auto;
          overflow-y: hidden;

          &::-webkit-scrollbar{
            height: 0px;
          }

          .site-card{
            min-width: 283px;

            .name{
              -webkit-line-clamp: 1;
            }
          }
        }
      }
    }
  }
}
