@import 'client/styles/mixins';
@import 'site/styles/mediaBreakpoints';

.wrapper-support-measure-topics{
  padding: 64px 0px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .head{
    width: 100%;

    p{
      display: flex;
      gap: 10px;
      align-items: center;
      @include P4;
      color: var(--deep-blue);
      flex-shrink: 0;

      &::after{
        content: "";
        flex-grow: 1;
        height: 1px;
        background: var(--deep-blue);
        display: block;
      }
    }
  }

  .items{
    display: flex;
    gap: 24px;
    flex-wrap: wrap;

    .topic-card-item{
      display: block;
      width: calc((100% - 72px) / 4);
      border-radius: 8px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, .16);

      .top{
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        overflow: hidden;

        img{
          object-fit: cover;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          transition: all .5s;
        }

        .topic-btn{
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px 16px;
          height: 42px;
          text-align: center;
          background: var(--deep-blue);
          position: absolute;
          bottom: 16px;
          left: 16px;
          width: calc((100% - 32px));
          border-radius: 50px;

          .topic-name{
            color: var(--white);
            @include P4;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

        }
      }

      &:hover{
        img{
          transform: scale(1.1);
        }
      }
    }
  }
}

/* Черный фон, белый текст */
html.color-inversion{
  .wrapper-support-measure-topics{
    .topic-btn{
      @include inversion-color-with-border;
    }
  }
}

@media(max-width: $mobile-width-breakpoint){
  .wrapper-support-measure-topics{
    padding: 32px 16px;
    .items{
      .topic-card-item{
        width: 100%;
      }
    }
  }
}