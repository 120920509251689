@import 'site/styles/mediaBreakpoints';
@import "site/styles/mixins";

.npa-single {
	padding-block: 48px 64px;
	display: flex;
	flex-direction: column;
	gap: 24px;

	.npa-comments {
		.form-field label {
			font-size: 14px;
			color: var(--grey-2);
		}
		.email-valid {
			display: flex;
			flex-direction: column;
			gap: 10px;
			div {
				color: var(--red);
			}
		}
	}

	.block {
		display: flex;
		flex-direction: column;
		gap: 16px;
		padding: 24px;
		background-color: white;
		border-radius: 8px;
	}

	.general {
		padding: 0;
		gap: 0;

		.top {
			display: flex;
			gap: 16px;
			padding: 24px;
			flex-direction: column;
			border-bottom: 1px solid var(--grey-5);
		}

		.files {
			display: flex;
			flex-direction: column;

			.file {
				display: flex;
				gap: 16px;
				padding: 24px;
				align-items: center;

				.download {
					background-color: var(--grey-6);
					border-radius: 24px;
					width: 84px;
					height: 32px;
					justify-content: center;
					align-items: center;
					border: none;
					display: flex;
					gap: 8px;
					color: var(--grey-1);
					cursor: pointer;
					text-decoration: none;

					img {
						width: 20px;
						height: 20px;
					}

					.extention {
						text-transform: uppercase;
					}
				}
			}
		}

		.status-date {
			display: flex;
			justify-content: space-between;

			.status .right {
				color: var(--deep-blue);
			}
		}
	}

	.info {
		.table {
			display: flex;
			flex-direction: column;
			gap: 0;

			.row {
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 8px;
				margin: 0;
				padding-block: 8px;
			}

			.row:not(:last-child) {
				border-bottom: solid 1px var(--grey-5);
			}
		}
	}

	.stages {
		display: flex;
		gap: 24px;

		.stage {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 10px;

			&:first-child .scale::before {
				background-color: var(--blue);
			}

			&:last-child .scale::after {
				width: calc(50% - 18px);
			}

			.stage-indicator {
				flex-shrink: 0;
				width: 32px;
				height: 32px;
				display: flex;
				align-items: center;
				justify-content: center;

				svg {
					width: 100%;
					height: 100%;
					display: none;
				}

				.circle {
					width: 28px;
					height: 28px;
					border-radius: 28px;
					border: solid 8px var(--blue);
					display: none;
				}
			}

			.scale {
				display: flex;
				align-items: center;
				gap: 2px;

				&::before,
				&::after {
					flex-shrink: 0;
					content: "";
					display: block;
					height: 4px;
				}

				&::before {
					width: calc(50% - 18px);
					background-color: var(--blue);
				}

				&::after {
					width: calc(50% + 6px);
					background-color: var(--blue);
				}
			}

			.text {
				flex: 1;
				padding: 24px;
				border-radius: 8px;
				background: var(--grey-7);
			}

			&.finished {
				.stage-indicator svg {
					display: block;
				}

				.text {
					border: 1px solid var(--blue);
				}
			}

			&.started {
				.stage-indicator .circle {
					display: block;
				}

				.scale::after {
					opacity: 0.12;
				}

				.text {
					border: 1px solid var(--blue);
				}
			}

			&.not-started {
				.stage-indicator .circle {
					display: block;
					opacity: 0.12;
				}

				.scale::before,
				.scale::after {
					opacity: 0.12;
				}

				.text {
					color: var(--grey-3);
				}
			}
		}
	}

	.discussion {
		.login {
			a {
				color: var(--blue);
				text-decoration: underline;
				text-decoration-skip-ink: none;
			}
		}
		.form-sent {
			display: flex;
			flex-direction: column;
			gap: 16px;
			align-items: center;

			svg {
				width: 40px;
				height: 40px;
				color: #75bf78;
			}

			.message {
				color: var(--grey-1);
				text-align: center;
				font-size: 14px;
				line-height: 26px;
			}

			button {
				padding: 0 24px;
				background-color: var(--grey-6);
				border-radius: 24px;
				border: none;
				font-size: 16px;
				color: var(--grey-1);
			}
		}
	}
}

.field {
	display: flex;
	gap: 8px;

	.left {
		color: var(--grey-2);
	}
}

/* Черный фон, белый текст */

html.color-inversion{
	.npa-single{
		@include inversion-color-with-border;

		.general, .info, .procedure, .block{
			background: black;
		}

		.file .download, .stage .text, input, .form-submit button{
			@include inversion-color-with-border;
		}

		.form-submit button:disabled{
			opacity: .3;
		}

		.stage-indicator .circle{
			border-color: white !important;
		}

		.scale:before, .scale:after{
			background: white !important;
		}
	}
}

@media(max-width: $mobile-width-breakpoint){
	.npa-single{
		margin: 0px !important;
		padding: var(--mobile-wrapper-content-padding-vertical) !important;

		.general{
			@include flex-column;

			.top{
				padding: 0px;
				padding-bottom: 16px;

				h4{
					@include h4-mobile;
					color: var(--black);
				}

				.status-date{
					flex-direction: column;
					gap: var(--mobile-small-row-gap);
				}

				.text.p2{
					@include p2-mobile;
				}

				.from{
					flex-direction: column;
					gap: 4px;

					& > div{
						@include p2-mobile;
					}
				}
			}

			.files{
				padding: var(--mobile-wrapper-content-padding-vertical);
				@include flex-column;
				gap: var(--mobile-standart-row-gap);

				.file{
					padding: 0px;
					flex-direction: column-reverse;
					align-items: flex-start;
					gap: var(--mobile-small-row-gap);

					.download{
						width: 100%;
						justify-content: center;
					}
				}
			}
		}

		.block{
			padding: var(--mobile-wrapper-content-padding-all);

			h4{
				@include h4-mobile;
				color: var(--black);
			}

			.table{

				.row{
					@include flex-column;
					gap: 4px;

					.p2{
						padding: 0px;
						@include p2-mobile;

						&.grey2{
							color: var(--grey-2);
						}
					}
				}
			}

			.stages{
				@include scroll-horizontal;
				align-items: flex-start;
				gap: 0px !important;

				.stage{
					min-width: max-content;

					.scale{

						&:before, &:after{
							width: auto;
							flex-grow: 1;
							background-color: var(--blue);
						}
					}

					.text{
						padding: 16px;
						margin: 0px 16px;
					}
				}
			}
		}

	}
}
