@import 'site/styles/mediaBreakpoints';
@import "site/styles/mixins";

.citizens-appeals-container {
	display: flex;
	align-items: flex-start;
	gap: 24px;
	align-self: stretch;

	.verification-wrapper{
		display: flex;
		gap: 16px;
		flex-direction: column;

		.sended-message{
			width: 100%;
		}

		& > div{
			display: flex;
			gap: 24px;

			button{
				flex-shrink: 0;
			}
		}

		.error{
			color: var(--red);
		}

	}

	.esia-btn-login, .esia-btn-logout{
		display: flex;
		justify-content: center;
		width: 100%;
	}

	.esia-btn-logout{
		.wrapper-btn{
			padding: 10px 16px;
			display: flex;
			gap: 10px;
			align-items: center;
			background: var(--light);
			border-radius: 50px;
		}
	}

	.sp-dropdown {
		height: calc(var(--element-height-no-px) * 1em / 16);
		position: relative;
		border-color: var(--grey-5);
		border-radius: 24px;
		border: 1px solid var(--grey-5) !important;
		--dropdown-selected-bg: var(--deep-blue);
		--dropdown-selected-text: var(--white);
		color: var(--black);
	}

	.citizens-appeals {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 24px;
		align-self: stretch;

		.citizens-appeals-form {
			width: 100%;
			padding: 24px;
			border-radius: 8px;
			border: 2px solid var(--grey-6);
			background: var(--white);

			.ui-form {
				width: 100%;
				gap: 24px;
				display: flex;
				flex-direction: column;
			}



			.field-label label {
				line-height: 160%;
				color: var(--grey-2);
			}

			.text-info {
				color: var(--grey-1);
			}

			.recipient-position {
				font-style: italic;
			}

			.field-text textarea {
				border-radius: 24px;
				padding: 16px;
			}

			.attach {
				display: flex;
				flex-direction: column;

				.attachments .upload input[type='file'] + label {
					color: var(--deep-blue) !important;
				}
			}

			.checkbox-approval {
				display: flex;
				gap: 10px;
			}

			.foot-info {
				color: var(--grey-1);
			}

			.legal-entity-items {
				display: flex;
				justify-content: space-between;

				.legal-entity-button {
					border-bottom: none;
				}
			}

			.post-address {
				display: flex;
				gap: 24px;

				.postcode,
				.house,
				.apartment {
					width: 153px;
				}

				.street {
					width: 350px;
				}
			}
		}

		.without-middle-name {
			.ui-checkbox {
				.ui-checkbox-toggle {
					margin-top: 12px;
				}
			}
		}

		.organization-button {
			display: flex;

			.button {
				font-weight: 400;
				line-height: 150%;

				&.active {
					border: 2px solid var(--bright);
				}
			}
		}

		.add-coauthor-button {
			width: 100%;
			font-weight: 400;
			line-height: 150%;
		}

		.add-co-author-info {
			text-align: center;
			color: var(--grey-2);
		}
	}

	.sidebar {
		display: flex;
		width: 282px;
		flex-direction: column;
		gap: 24px;

		.sidebar-citizens-appeals {
			display: flex;
			align-items: center;
			color: var(--deep-blue);
			text-transform: uppercase;

			.line {
				width: 186px;
				margin-left: 10px;
				border-bottom: 1px solid var(--deep-blue);
			}
		}
		.appeals-count-container {
			.counter {
				color: var(--deep-blue);
			}
		}
		.sidebar-button {
			width: 100%;
		}
	}
}

.appeal-sent-page {
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding-bottom: 24px;
}

.form-evaluation-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 24px;
	flex: 1 0 0;

	.form-evaluation {
		display: flex;
		padding: 24px;
		flex-direction: column;
		align-items: flex-start;
		gap: 24px;
		align-self: stretch;

		.evaluation-form {
			width: 100%;
			padding: 24px;
			border-radius: 8px;
			border: 2px solid var(--grey-6);
			background: var(--white);
			display: flex;
			flex-direction: column;
			gap: 24px;

			.conclusion,
			.yes-no,
			.date,
			.rating,
			.decision {
				display: flex;
				flex-direction: column;
				gap: 16px;

				.radio-input {
					display: flex;
					flex-direction: column;
					gap: 16px;

					.radio-item {
						display: flex;
						gap: 10px;
					}

					.radio-item label strong {
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 150%;
					}
				}

				.header {
					font-weight: 600;
					line-height: 150%;
				}
			}
		}
	}
}

/* Черный фон, белый текст */

html.color-inversion{
	.citizens-appeals-container{

		.citizens-appeals-form{
			background: black !important;
		}

		.organization-button button, .add-coauthor-button, .sidebar-button, .esia-btn-login button{
			background: black !important;
		}

		.ui-checkbox-toggle, .form-submit button, .esia-btn-logout .wrapper-btn, .esia-btn-logout .wrapper-btn button{
			@include inversion-color-with-border;
		}

		.form-submit button:disabled{
			opacity: .3;
		}
	}
	.appeal-sent-page-single{
		button{
			@include inversion-color-with-border;
		}
	}
}

@media(max-width: $tabled-width-breakpoint){
	.citizens-appeals-container{
		flex-direction: column-reverse;

		.sidebar{
			width: 100%;

			.sidebar-citizens-appeals{

				.line{
					flex-grow: 1;
				}
			}
		}
	}
}

@media(max-width: $mobile-width-breakpoint){
	.citizens-appeals-container{
		padding: 0px !important;
		margin: 0px !important;
		flex-direction: column-reverse;
		gap: var(--mobile-standart-row-gap);

		.citizens-appeals{
			gap: 0px;

			.p1{
				@include p1-mobile;
				padding: var(--mobile-wrapper-content-padding-all) !important;
			}

			.citizens-appeals-form{
				padding: var(--mobile-wrapper-content-padding-all);

				.ui-form{
					gap: var(--mobile-standart-row-gap);

					.text-info.p3{
						margin-top: 10px;
					}

					.ui-checkbox{
						.ui-checkbox-toggle{
							margin: 0px;
							width: 20px;
							height: 20px;
						}
					}

					.form-field{
						margin: 0px;
						@include flex-column;
						gap: 10px;

						label{
							margin: 0px;
						}

						.sp-trigger{
							height: max-content;
							padding: 13px 16px;

							.filter{

								.filter-content{
									padding-left: 0px;
								}

								&:before{
									top: 20px;
								}
							}
						}

						.attachments{
							margin: 0px;

							.upload{
								margin: 0px;
							}
						}

						&.attach{

							.text-info.p3{
								margin: 0px !important;
							}
						}
					}

					.sp-row{
						flex-direction: column;
						gap: var(--mobile-standart-row-gap);

						.sp-col{
							margin: 0px;
						}
					}

					.without-middle-name{
						flex-direction: row-reverse;
						align-items: center;
						justify-content: flex-end;

						label{
							@include p1-mobile;
							color: var(--black);
						}
					}

					.organization-button{
						@include flex-row;
						gap: var(--mobile-standart-row-gap);

						button{
							margin: 0px;
							width: calc((100% - var(--mobile-standart-row-gap)) / 2)
						}
					}

					.post-address{
						flex-direction: column;
						gap: var(--mobile-standart-row-gap);

						.form-field{
							width: 100%;
						}
					}

					.checkbox-approval{

						.p2{
							@include p2-mobile;
						}
					}
				}
			}
		}

		.sidebar{
			padding: var(--mobile-wrapper-content-padding-all);
			width: 100%;
			gap: var(--mobile-small-row-gap);

			.sidebar-citizens-appeals{

				.line{
					width: auto;
					flex-grow: 1;
				}
			}

			.p2{
				@include p2-mobile;
				margin-top: 8px;
			}

			.h1{
				@include h1-mobile;
			}

			button{
				margin-top: 8px;
			}
		}
	}

	.appeal-sent-page-single{
		padding: var(--mobile-wrapper-content-padding-all) !important;
		margin: 0px !important;
		@include flex-column;
		gap: var(--mobile-standart-row-gap);

		.appeal-sent-page{
			gap: var(--mobile-standart-row-gap);
			padding: 0px;

			.p1{
				@include p1-mobile;
			}
		}
	}

	.form-evaluation-container{
		padding: 0px !important;
		margin: 0px !important;

		.form-evaluation{
			padding: 0px;
			gap: 0px;

			& .p1{
				padding: var(--mobile-wrapper-content-padding-all);
			}

			.evaluation-form{
				padding: var(--mobile-wrapper-content-padding-all);

				.ui-form{
					@include flex-column;
					gap: var(--mobile-standart-row-gap);

					& > div{
						gap: var(--mobile-small-row-gap);

						.p2{
							@include p2-mobile-bold;
							color: var(--black);
						}

						.form-field{
							margin: 0px;

							.radio-input{
								gap: var(--mobile-small-row-gap);

								.radio-item{

									label{
										flex-grow: 1;
										white-space: normal;
									}
								}
							}

							.sp-date-picker{
								min-width: 50%;
							}

							.rating-star{
								justify-content: space-between;

								svg{
									width: 48px;
									height: 48px;
								}
							}
						}
					}
				}
			}
		}
	}
}
