@import 'site/styles/mediaBreakpoints';
@import "site/styles/mixins";

.event-page {
	display: flex;
	flex-direction: column;
	gap: 32px;
	width: 666px;
	padding: 40px 40px 0;
	height: 100%;
	overflow-x: hidden;

	.header {
		display: flex;
		gap: 24px;

		.text {
			display: flex;
			gap: 8px;
			flex-direction: column;

			.date {
				color: var(--deep-blue);
			}

			.type {
				color: var(--grey-2, #8291a4);
			}

			.status {
				color: var(--grey-3, #a8afb8);
				background: var(--grey-5, #e4eef2);
				width: min-content;
				border-radius: 30px;
				padding: 4px 16px;
			}
		}

		.image {
			border-radius: 8px;

			svg {
				border-radius: 8px;
			}

			img {
				min-width: 150px;
				max-width: 250px;
				max-height: 250px;
				border-radius: 8px;
				object-fit: contain;
				height: 100%;
			}
		}
	}

	.form-field {
		white-space: normal;
		label {
			text-transform: uppercase;
			font-weight: 600 !important;
		}
		.text {
			img {
				object-fit: contain;
				max-width: 100%;
			}
		}
	}

	.applications {
		display: flex;
		flex-wrap: wrap;
		gap: 24px;
		cursor: pointer;

		.application {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex: 0 0 calc(50% - 24px);
			padding: 0 16px;
			border-radius: 8px;
			background: var(--grey-6);
			color: var(--black);
			text-decoration: inherit;
		}
	}

	.dates {
		display: flex;
		flex-direction: column;
		align-items: flex-end;

		.published {
			color: var(--grey-1, #6D7886);
		}

		.last-update {
			color: var(--grey-2, #8291A4);
		}
	}

	.footer {
		display: flex;
		margin-top: auto;
		padding: 24px 0;
		position: sticky;
		bottom: 0;
		background: var(--white);

		button {
			border-radius: 24px;

			&.request:not([disabled]) {
				background: var(--deep-blue);
				color: var(--white);

				svg * {
					fill: var(--white);
				}
			}

			&.link:not([disabled]) {
				background: var(--primary-light, #CCE3F0);
				color: var(--deep-blue);

				svg * {
					fill: var(--deep-blue);
				}
			}
		}
	}
}

body:has(.event-page) {
	overflow: hidden;
}

.sp-popup:has(.event-popup) {
	flex-direction: row-reverse;
	justify-content: normal;
	align-items: normal;
	.event-popup {
		max-height: initial;

		.sp-popup-content {
			max-height: calc(100vh);
			height: 100%;
			padding: 0;
		}
	}
}

html.color-inversion{
	.event-page{
		@include inversion-color-with-border;

		.status, .application{
			@include inversion-color-with-border;
		}

		.footer{
			background: black;

			button{
				@include inversion-color-with-border;
			}
		}
	}
}

/* Черный фон, белый текст */

html.color-inversion{
	.feedback{
		input, .actions button{
			@include inversion-color-with-border;
		}
		.actions button:disabled{
			opacity: .3;
		}
	}
}

@media(max-width: $tabled-width-breakpoint){
	.event-popup{

		.sp-popup-content{

			.event-page{

				.footer{
					margin: 0px;
				}
			}
		}
	}
}

@media(max-width: $mobile-width-breakpoint){

	body:has(.event-popup){
		overflow: hidden;
	}

	.event-popup{
		max-width: 100%;
		min-width: 100%;
		padding: var(--mobile-wrapper-content-padding-all);

		&::-webkit-scrollbar{
			width: 0px;
		}

		.event-page{
			padding: 0px;
			width: 100%;
			@include scroll-vertical;
			gap: var(--mobile-standart-row-gap);

			.wrapper-control-back{
				@include flex-row;
				align-items: center;
				gap: var(--mobile-small-row-gap);
				color: var(--grey-3);
			}

			.header{
				flex-direction: column;
				gap: var(--mobile-standart-row-gap);

				.image{
					max-width: 100%;

					img{
						width: 100%;
						min-width: 100%;
						max-width: 100%;
						max-height: initial;
						height: auto;
					}
				}
			}
		}
	}
}