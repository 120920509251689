@import 'site/styles/mediaBreakpoints';
@import "site/styles/mixins";

.management-person-page {
  position: relative;

  .management-person-page-title {
    margin-bottom: 48px;
  }

  .application-wrapper {
    .application {
      padding-top: 20px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;

      .docs {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 0 0 calc(50% - 24px);
        padding: 10px 16px;
        border-radius: 8px;
        background: var(--grey-6);
        color: var(--black);
        text-decoration: inherit;
      }
    }
  }

  .management-person-page-content {
    display: flex;
    gap: 24px;
    width: 100%;

    .social-bottom {
      position: absolute;
      top: 100px;
      right: -56px;

      .share-links {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }

    .documents-page {
      margin: 15px 0;
    }

    .management-person-card-pic {
    }

    .management-person-page-content-wrapper {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .text-block-wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .text-block-title {
          color: var(--basic-black, #464a51);
        }

        .management-person-page-content-text {
          color: var(--basic-black, #464a51);
        }
      }
    }
  }
}

/* Черный фон, белый текст */

html.color-inversion {
  .management-person-page {
    .docs {
      @include inversion-color-with-border;
    }
  }
}

@media (max-width: $tabled-width-breakpoint) {
  .management-person-page {
    .management-person-page-content {
      .social-bottom {
        position: relative;
        top: 0;
        right: 0;
      }
    }
  }
}

@media (max-width: $mobile-width-breakpoint) {
  .management-person-page {
    margin: 0px !important;
    padding: var(--mobile-wrapper-content-padding-all) !important;
    @include flex-column;
    gap: var(--mobile-standart-row-gap);

    .management-person-page-title {
      margin: 0px;
      @include h1-mobile;
    }

    .management-person-page-content {
      flex-direction: column;
      gap: var(--mobile-standart-row-gap);

      .social-bottom {
        position: relative;
        top: 0;
        left: 0;

        .share-links {
          flex-direction: row;
          gap: var(--mobile-standart-row-gap);
          justify-content: center;

          a {
            width: 48px;
            height: 48px;
            @include flex-column;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }

    .management-person-page-content-wrapper {
      gap: var(--mobile-standart-row-gap) !important;

      img {
        width: 100%;
      }

      .p1 {
        @include p1-mobile;
      }
    }
  }
}
