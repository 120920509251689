@import 'site/styles/mediaBreakpoints';
@import "client/styles/mixins";

.useful-links {
	display: flex;
	flex-direction: column;
	width: var(--site-width, 1200px);

	.title {
		padding-bottom: 0;
		margin-bottom: 18px;
	}

	.sp-tabs-container {
		margin-bottom: 24px;

		.sp-tab {
			padding: 0;
			padding-bottom: 8px;
			font-size: 16px;
			margin-right: 16px;
			transition: all 0.3s;
			color: #8291a4;
			font-weight: 400;
			opacity: .6;

			&.active {
				color: #1051a1;
				font-weight: 600;
				border-bottom: solid 9px #1051a1;
				opacity: 1;
			}
		}
	}

	h3 {
		font-size: 18px;
		font-weight: 600;
	}

	.slider {
		margin: 0;
		width: 100%;

		.slide-items {
			display: flex;
			transition: all 300ms;
		}
	}

	.slide-item {
		flex-shrink: 0;
		width: 100%;

		display: grid;
		grid-template-columns: 1fr;
		gap: 24px;

		color: #6d7886;
		word-wrap: break-word;

		&.has-main-link {
			grid-template-columns: 1fr 3fr;
		}

		.main-link {
			height: 100%;

			p {
				font-size: 16px;
				font-weight: 600;
			}
		}
	}

	.links-grid {
		height: 100%;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		gap: 24px;

		&.cols-3 {
			grid-template-columns: 1fr 1fr 1fr;
		}
	}

	.link {
		width: 100%;
		height: 100%;
		background-color: #e4eef2;
		overflow: hidden;
		padding-left: 4px;

		.content {
			background-color: white;
			color: #6d7886;
			text-decoration: none;
			width: 100%;
			height: 100%;
			padding: 16px;
			padding-left: 12px;
			display: flex;
			flex-direction: column;
			gap: 16px;
			justify-content: space-between;
			font-size: 14px;
			font-weight: 400;
			line-height: 21px;
			word-wrap: break-word;

			.content-img-wrapper {
				display: flex;
				justify-content: flex-end;
			}
		}
	}

	img {
		width: 160px;
		height: 60px;
		align-self: flex-end;
		object-position: right;
		object-fit: contain;
	}

	.two-rows img {
		height: 37px;
		width: 106px;
	}
}

/* Черный фон, белый текст */
html.color-inversion{
	.secondary-layout:has(.useful-links){
		background: black;

		.link{
			@include inversion-color-with-border;

			a{
				background: black;
			}
		}
	}
}

@mixin flex{
	display: flex;
	flex-direction: column;
	gap: var(--mobile-standart-row-gap);
}

@media(max-width: $mobile-width-breakpoint){
	.secondary-layout{
		padding: var(--mobile-section-padding);

		.useful-links{
			width: 100%;
			@include flex;

			.section-card__title{
				margin: 0px;
			}

			& > .content{
				@include flex;

				.sp-tabs-container{
					margin-bottom: 0px;
				}

				.sp-tabs-well{
					height: max-content;

					&::-webkit-scrollbar{
						height: 0px;
					}
				}

				.slider{

					.indicators{
						display: none;
					}

					.slide-items{
						flex-direction: column;
						gap: var(--mobile-standart-row-gap);

						.slide-item{
							@include flex;
							height: max-content;

							.links-grid{
								grid-template-columns: 1fr 1fr;
								gap: var(--mobile-standart-row-gap);

								.link{
									padding: 16px;
									background: var(--white);
									border-left: 4px solid var(--grey-5);
									border-radius: 8px;

									.content{
										padding: 0px;
										gap: var(--mobile-standart-row-gap);

										p{
											@include p2-mobile;
											font-size: 12px;
											color: var(--grey-1);
											word-wrap: break-word;
											overflow: hidden;
											text-overflow: ellipsis;
											display: -webkit-box;
											-webkit-line-clamp: 4;
											-webkit-box-orient: vertical;
										}

										img{
											height: 24px;
											width: auto;
										}
									}
								}
							}

							.main-link{
								.content{
									gap: var(--mobile-medium-row-gap);

									p{
										@include h6-mobile;
										color: var(--grey-1)
									}

									img{
										height: 40px;
										width: auto;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
