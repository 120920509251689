@import 'site/styles/mediaBreakpoints';

.question-answer-page-container-isLoading {
	margin-top: 225px;
}

.question-answer-container {
	.header {
		position: fixed;
		top: 110px;
		z-index: 100;
	}

	.answer-questions {
		display: flex;
		justify-content: space-between;
		padding: 16px 0 16px 0;
		flex-grow: 1;

		.answer-questions-item {
			width: 100%;
			padding-right: 24px;
		}

		.sidebar {
			position: sticky;
			flex-basis: 285px;
			flex-shrink: 0;
			width: 285px;
			margin-bottom: 16px;

			.question-answer-tags-container {
				display: flex;
				flex-wrap: wrap;
				padding-bottom: 24px;

				.tags {
					height: 25px;
					cursor: pointer;
					color: var(--black);
					word-wrap: break-word;
					opacity: 0.6;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 150%;
					text-decoration: underline;
					padding-right: 10px;
				}
			}

			.right {
				text-align: right;
				cursor: pointer;
			}

			.sidebar-question-answer {
				display: flex;
				align-items: center;
				color: var(--deep-blue);
				text-transform: uppercase;
				padding-bottom: 24px;
				.line {
					width: 100%;
					margin-left: 10px;
					border-bottom: 1px solid var(--deep-blue);
				}
			}
		}
	}
}

.question-answer-page-container {
	display: flex;

	.question-answer-page-item {
		width: 100%;
		padding-right: 24px;

		.head {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-bottom: 24px;
			gap: 16px;

			.category-subcategory {
				display: flex;
				font-weight: 500;
				color: var(--grey-3);
				overflow: hidden;

				& > a {
					flex-shrink: 0;
				}

				.category {
					padding-right: 10px;
					border-right: 1px solid var(--grey-3);
					color: var(--grey-3);
					cursor: pointer;
				}

				.subcategory {
					padding-left: 10px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}

			.to-portal {
				display: flex;
				align-items: center;
				padding-right: 24px;

				.go-portal {
					padding-left: 10px;
					color: var(--grey-3);
					white-space: nowrap;
					cursor: pointer;
				}
			}
		}

		.question-answer-item {
			.title {
				padding-bottom: 24px;
				line-height: 44px;
			}

			.applications {
				display: flex;
				flex-wrap: wrap;
				gap: 24px;
				cursor: pointer;
				padding-top: 32px;

				.application {
					display: flex;
					align-items: center;
					justify-content: space-between;
					flex: 0 0 calc(50% - 24px);
					padding: 0 16px;
					border-radius: 8px;
					background: var(--grey-6);
					color: var(--black);
					text-decoration: inherit;
				}
			}

			.date-category-subcategory {
				display: flex;
				padding-bottom: 24px;
				color: var(--grey-2);

				.date,
				.category {
					padding-right: 10px;
					padding-left: 10px;
					border-right: 1px solid var(--grey-3);
				}

				// :nth-child(-n + 2) {
				// 	padding-right: 10px;
				// 	padding-left: 10px;
				// 	border-right: 1px solid var(--grey-3);
				// }

				// :nth-child(3) {
				// 	padding-left: 10px;
				// }
			}

			.big-image {
				display: flex;
				justify-content: center;
				padding-bottom: 24px;
			}

			.description,
			.tags-social,
			.back-forward {
				//max-width: 870px;
				padding-right: 24px;
			}

			.tags-social {
				padding-top: 36px;
				display: flex;
				justify-content: space-between;
				color: var(--grey-2);

				.social-share {
					display: flex;
					align-items: center;
				}
			}

			.back-forward {
				display: flex;
				justify-content: space-between;
				margin: 35px 0;

				.back,
				.forward {
					display: flex;
					align-items: center;
					cursor: pointer;

					.p4 {
						color: var(--grey-2);

						&:hover {
							color: var(--black);
						}
					}

					.arrow-left,
					.arrow-right {
						margin-right: 10px;

						.s-fill {
							fill: var(--deep-blue);
						}

						.s-stroke {
							stroke: var(--black);
						}
					}
				}
			}
		}
	}

	.sidebar {
		position: sticky;
		flex-basis: 285px;
		flex-shrink: 0;
		width: 285px;
		margin-bottom: 30px;

		.sidebar-news {
			display: flex;
			align-items: center;
			color: var(--deep-blue);
			text-transform: uppercase;
			margin-bottom: 16px;
			.line {
				width: 100%;
				margin-left: 10px;
				border-bottom: 1px solid var(--deep-blue);
			}
		}
	}
}

@media (max-width: $mobile-width-breakpoint) {
	.question-answer-container {
		.answer-questions {
			padding: 0px;
			flex-direction: column;
			gap: var(--mobile-standart-row-gap);

			.wrapper-questions-answers-filter-mobile {
				@include flex-column;
				gap: var(--mobile-standart-row-gap);
				.sort {
					& > span {
						display: none;
					}

					.sp-dropdown {
						width: 100% !important;
						margin: 0px;

						.filter {
							margin: 0px;
							width: 100%;
							@include flex-row;
							gap: var(--mobile-small-row-gap);
							flex-direction: row-reverse;
							justify-content: center;

							&::before {
								display: none;
							}

							.filter-content {
								margin: 0px;
								flex-grow: unset;
								flex-basis: auto;
								padding: 0px;

								.show-value {
									@include p1-mobile;
									margin: 0px;
									padding: 0px;
									justify-content: center;
								}
							}

							.filter-arrow {
								display: block;
								width: 0px;
								transform: none !important;

								&::before {
									content: '';
									display: block;
									width: 20px;
									height: 20px;
									background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='20' viewBox='0 0 21 20' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.41667 5C3.18655 5 3 5.18655 3 5.41667V6.25C3 6.48012 3.18655 6.66667 3.41667 6.66667H17.5833C17.8135 6.66667 18 6.48012 18 6.25V5.41667C18 5.18655 17.8135 5 17.5833 5H3.41667ZM5.91667 9.16667C5.68655 9.16667 5.5 9.35321 5.5 9.58333V10.4167C5.5 10.6468 5.68655 10.8333 5.91667 10.8333H15.0833C15.3135 10.8333 15.5 10.6468 15.5 10.4167V9.58333C15.5 9.35321 15.3135 9.16667 15.0833 9.16667H5.91667ZM8 13.75C8 13.5199 8.18655 13.3333 8.41667 13.3333H12.5833C12.8135 13.3333 13 13.5199 13 13.75V14.5833C13 14.8135 12.8135 15 12.5833 15H8.41667C8.18655 15 8 14.8135 8 14.5833V13.75Z' fill='%238291A4'/%3E%3C/svg%3E");
								}

								svg {
									display: none;
								}
							}
						}

						&.active {
							.filter {
								.filter-arrow {
									&:before {
										background: none;
									}
								}
							}
						}
					}
				}
			}

			.mobile-sidebar {
				@include flex-column;

				& > .sidebar:first-child {
					display: none;
				}

				.sidebar {
					margin: 0px;
					width: 100%;
					@include flex-column;
					gap: var(--mobile-medium-row-gap);
					flex-basis: unset;

					.wrapper-popular {
						@include flex-row;
						gap: var(--mobile-medium-row-gap);
						@include scroll-horizontal;

						.small-question {
							min-width: 282px;
							max-width: 282px;
							margin: 0px;
							padding: 0px;
							border: none;
							@include flex-column;
							gap: var(--mobile-small-row-gap);

							div {
								padding: 0px;
							}

							.category-subcategory {
								max-width: 100%;
								overflow: hidden;
								gap: 10px;

								.subcategory {
									padding: 0px !important;
								}
								.category {
									padding-right: 10px;
								}
								.category,
								.subcategory {
									min-width: max-content;
								}
							}
						}
					}
				}
			}

			.wrapper-content {
				display: flex;
				flex-direction: column;
				gap: var(--mobile-standart-row-gap);

				.question-answer-card {
					margin: 0px;
					gap: var(--mobile-standart-row-gap);

					.images {
						margin: 0px;
						min-width: max-content;
						height: max-content;

						img {
							height: 87px;
						}
					}

					.question-answer-card-items {
						flex-grow: 1;
						overflow: hidden;

						.text-middle {
							display: none;
						}

						.question-answer-title {
							@include h6-mobile;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 2;
						}

						.date {
							color: var(--grey-3);
						}
					}
				}
			}
		}
	}
	.question-answer-page-container {
		padding: var(--mobile-section-padding) !important;
		margin: 0px !important;
		flex-direction: column;
		gap: var(--mobile-standart-row-gap);

		.question-answer-page-item {
			padding: 0px;
			width: 100%;

			.question-answer-item {
				@include flex-column;
				gap: var(--mobile-standart-row-gap);

				.wrapper-back-link {
					@include flex-row;
					align-items: center;
					gap: var(--mobile-small-row-gap);
					@include p1-mobile;
					color: var(--grey-3);
				}

				.h1 {
					padding: 0px;
					@include h1-mobile;
					color: var(--black);
				}

				.wrapper-date-categories {
					.date-category-subcategory {
						flex-wrap: wrap;
						gap: 10px;
						padding: 0px;

						& > div {
							padding: 0px;
							border: 0px;
							@include flex-row;
							gap: 10px;
							@include p2-mobile;
							align-items: center;

							&::after {
								content: '';
								display: block;
								width: 1px;
								height: 17px;
								background: var(--grey-2);
							}
						}

						& > div:last-child {
							&::after {
								display: none;
							}
						}
					}
				}

				.big-image {
					padding-bottom: 0px;
					border-radius: 8px;
				}

				.description {
					@include p1-mobile;
					padding: 0px;
				}

				.tags-social {
					padding: 0px;
					flex-direction: column;
					gap: var(--mobile-standart-row-gap);

					& > div:first-child {
						@include flex-row;
						gap: 10px;
					}

					.social-share {
						flex-direction: column;
						gap: var(--mobile-standart-row-gap);

						.share {
							@include p1-mobile;
							color: var(--black);
						}

						.share-links {
							gap: var(--mobile-standart-row-gap);

							a {
								margin: 0px;
								width: 48px;
								height: 48px;
								@include flex-row;
								justify-content: center;
								align-items: center;

								div {
									width: max-content;
									height: max-content;

									svg {
										width: 24px;
										height: 24px;
										display: block;
									}
								}
							}
						}
					}
				}

				.back-forward {
					margin: 0px;
					flex-direction: column;
					gap: var(--mobile-standart-row-gap);

					a {
						opacity: 0.5;
						svg {
							path {
								fill: var(--black);
							}
						}

						.p4 {
							color: var(--black) !important;
						}
					}
				}
			}
		}

		.sidebar {
			width: 100%;
			@include flex-column;
			gap: var(--mobile-standart-row-gap);
			flex-basis: unset;
			margin: 0px;

			.list {
				@include flex-row;
				gap: var(--mobile-medium-row-gap);
				@include scroll-horizontal;

				a {
					max-width: 282px;
					min-width: 282px;
					margin: 0px;
					padding: 0px;
					border: none;
					@include flex-column;
					gap: var(--mobile-small-row-gap);

					.category-subcategory {
						padding: 0px;
						max-width: 100%;
						overflow: hidden;

						& > div {
							min-width: max-content;
						}
					}

					.title {
						margin: 0px;
					}
				}
			}
		}
	}
}
