@import "site/styles/mixins";

.widget4 {
  //для показа, потом удалить
  position: absolute;
  top: 10px;
  z-index: 99;

  width: 384px;
  height: 500px;
  border-radius: 8px;
  border: 1px solid var(--translucent-white-60);
  background: var(--translucent-white-30);
  backdrop-filter: blur(12px);

  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 24px 16px 24px;

    .link-header-container {
      display: flex;
      padding-top: 24px;
      padding-bottom: 16px;
      justify-content: space-between;
      height: 95px;
      align-items: center;
      border-bottom: 1px solid var(--translucent-white-60);
      text-decoration: none;


      .link-header {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        padding-right: 16px;
        color: var(--white);
        cursor: pointer;
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      &:hover {
        .link-header {
          color: var(--blue);
        }
      }
    }
  }
}

/* Черный фон, белый текст */
html.color-inversion{
  .widget4{
    @include inversion-color-with-border;
  }
}