//P
@mixin P1 {
	font-size: 16px;
	font-weight: 400;
	line-height: 150%;
}

@mixin P2 {
	font-size: 14px;
	font-weight: 400;
	line-height: 150%;
}

@mixin P2-bold {
	font-size: 14px;
	font-weight: 600;
	line-height: 140%;
}

@mixin P3 {
	font-size: 12px;
	font-weight: 400;
	line-height: 150%;
}

@mixin P4 {
	font-size: 12px;
	font-weight: 600;
	line-height: 150%;
	text-transform: uppercase;
}

@mixin P5 {
	font-size: 10px;
	font-weight: 400;
	line-height: 150%;
}

@mixin P6 {
	font-size: 10px;
	font-weight: 500;
	line-height: 150%;
	text-transform: uppercase;
}

@mixin P1-mobile {
	font-size: 14px;
}

@mixin P2-mobile {
	font-size: 13px;
}

@mixin P2-bold-mobile {
	font-size: 14px;
}

@mixin P3-mobile {}
@mixin P4-mobile {}
@mixin P5-mobile {}
@mixin P6-mobile {}

//H
@mixin H1 {
	font-size: 32px;
	font-weight: 600;
	line-height: 150%;
}

@mixin H2 {
	font-size: 32px;
	font-weight: 600;
	line-height: 150%;
	text-transform: uppercase;
}

@mixin H3 {
	font-size: 24px;
	font-weight: 400;
	line-height: 150%
}

@mixin H4 {
	font-size: 24px;
	font-weight: 600;
	line-height: 150%
}


@mixin H5 {
	font-size: 20px;
	font-weight: 600;
	line-height: 28px;
}


@mixin H6 {
	font-size: 16px;
	font-weight: 400;
	line-height: 150%;
}


@mixin H7 {
	font-size: 16px;
	font-weight: 400;
	line-height: 150%;
}



//H mobile 
@mixin H1-mobile {
	font-size: 24px;
}

@mixin H2-mobile {
	font-size: 24px;
}

@mixin H3-mobile {
	font-size: 24px;
}

@mixin H4-mobile {
	font-size: 20px;
}

@mixin H5-mobile {
	font-size: 18px;
}

@mixin H6-mobile {
	font-size: 16px;
}

@mixin H7-mobile {
	font-size: 14px;
}


@mixin line-clamp {
	word-wrap: break-word;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
}

@mixin inversion-color-with-border{
	background: black !important;
	border: 1px solid white !important;
}