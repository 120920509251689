@import "client/styles/mixins";

.desktop-menu,
.mobile-menu {
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: flex-start;

	.structure-element-container {
		display: flex;
		align-items: center;
	}

	.structure-element-menu {
		color: #fff;
		font-weight: 600;
		font-size: 14px;
		text-decoration: none;
	}

	.right {
		flex-grow: 1;
		flex-basis: 0;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}

	.links {
		display: flex;
		text-decoration: none;
		align-items: center;
		gap: 1px;
		font-size: 14px;

		&:first-child{
			flex-wrap: wrap;
		}

		.link {
			text-decoration: none;
			border: 2px solid transparent;

			svg {
				margin-right: 4px;
			}
		}

		.active {
			border-radius: 40px;
			border: 2px solid var(--primary-light, #cce3f0);
		}

		& > .link,
		.right {
			position: relative;
			display: flex;
			line-height: 150%;
			font-weight: 400;
			align-items: center;
			cursor: pointer;
			//transition: linear 1s;
			.link-content {
				padding: 6px 12px;
				display: flex;
				gap: 3px;
				align-items: center;

				span {
					//max-width: 150px;
					//white-space: nowrap;
					//overflow: hidden;
					//text-overflow: ellipsis;
				}
			}

			&:hover,
			&:active {
				background: rgba(16, 81, 161, 0.08);
				border-radius: 40px;

				svg {
					transform: scale(1.2);
					transition: linear 1s;
				}
			}

			.drop-down-menu {
				display: none;
				position: absolute;
				top: calc(100%);
				//margin-top: 4px;
				left: 0;
				border: 2px solid #ffffff4d;
				background-color: #1051a1;
				border-radius: 8px;
				padding: 12px;
				flex-direction: column;
				gap: 10px;
				width: fit-content;
				max-width: 480px;
				transform: scaleY(0);
				z-index: 100;

				li {
					display: flex;
					align-items: center;
					transition: color 0.3s;
					line-height: 21px;
					width: max-content;
					max-width: 100%;
					overflow-wrap: break-word;
					word-break: break-word;
					white-space: normal;

					&:hover {
						color: #2fa8ff;
					}

					.link-content {
						white-space: normal;
						padding: 6px 0px;

						span {
							white-space: normal !important;
							overflow-wrap: break-word;
						}

						svg{
							flex-shrink: 0;
							width: 15px;
							height: 15px;
						}
					}
				}
			}

			&:hover .drop-down-menu {
				display: flex;
				animation: growDown 300ms ease-in-out forwards;
				transform-origin: top center;
			}

			@keyframes growDown {
				0% {
					transform: scaleY(0);
				}

				80% {
					transform: scaleY(1.1);
				}

				100% {
					transform: scaleY(1);
				}
			}
		}

		& > .link.blue-border-background {
			.drop-down-menu {
				border: 2px solid #cce3f0;
				background-color: white;
				color: #464a51;

				li:hover {
					color: #1051a1;
				}
			}
		}
	}

	&.deep-blue {
		svg {
			*[stroke] {
				stroke: var(--deep-blue);
			}
			*[fill] {
				fill: var(--deep-blue);
			}
		}
	}
}
.secondary-menu-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.secondary-header-sitename {
		width: 700px;
		text-wrap: balance;

		word-wrap: break-word;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
}

.secondary-page-header-wrapper {
	display: flex;
	gap: 16px;
	padding: 24px 0;
	color: var(--black);
	white-space: nowrap;
	max-width: var(--site-width);
	width: 100%;
	margin: 0 auto;
}

/* Черный фон, белый текст */
html.color-inversion{
	.drop-down-menu{
		@include inversion-color-with-border;
	}
}
