@import "client/styles/mixins";

.gis-map {
	.filters-minimized {
		position: absolute;
		top: 10px;
		left: 10px;
		width: 40px;
		height: 40px;
		z-index: 5;
		background: #fff;
		border: 1px solid rgba(#000, 0.25);
		box-shadow: 1px 3px 3px rgba(#000, 0.15);
		border-radius: 4px;
		cursor: pointer;
		display: grid;
		place-items: center;
		color: var(--primary);
		font-size: 16px;

		@media (max-height: 960px) {
			&.private {
				left: calc(var(--sidebar-width) + 20px);
			}
		}

		&:hover {
			color: #000;
		}
	}

	.dynamic-layers-list {
		.sp-col {
			margin-right: 8px;
		}

		input {
			height: 30px;
		}
	}

	.filters-wrapper {
		position: absolute;
		left: 10px;
		width: 300px;
		top: 10px;
		bottom: 70px;
		cursor: default;
		z-index: 5;

		.minimize-btn {
			position: absolute;
			right: -35px;
			top: 0;
			width: 30px;
			height: 30px;
			background: var(--primary);
			color: #fff;
			display: grid;
			place-items: center;
			border: 1px solid rgba(#000, 0.15);
			border-radius: 4px;
			cursor: pointer;

			&:hover {
				color: #000;
			}
		}

	}

	.map-filters {
		font-size: 12px;
		user-select: none;
		backdrop-filter: blur(20px);
		-webkit-backdrop-filter: blur(20px);
		-webkit-backface-visibility: hidden;
		-webkit-transform: translate3d(0,0,0);
		-webkit-transform: translateZ(0);
		backface-visibility: hidden;
		transform: translate3d(0,0,0);
		transform: translateZ(0);
		box-shadow: 1px 3px 3px rgba(#000, 0.15);
		max-height: calc(100vh - 200px);
		overflow: auto;
		background: rgba(#fff, 0.7);

		& > .actions {
			padding: 10px 15px;
		}

		.sp-dropdown {
			width: 200px;
			height: initial;
		}

		.sp-slide-input {
			width: 200px;
			margin-left: 1px;
		}

		.filters-layout {
			border-bottom: 1px solid rgba(#024, 0.15);

			.hdr {
				font-weight: bold;
				height: 30px;
				line-height: 30px;
				background: var(--bg);
				display: flex;

				.layer-toggle {
					padding-left: 10px;
					flex-grow: 1;
					flex-shrink: 1;
					.hint-component {
						margin-left: 5px;
					}

					.ui-checkbox {
						.ui-checkbox-toggle {
							background: var(--light-grey);
						}
						svg {
							color: var(--primary);
						}
					}
				}

				.layer-actions {
					flex-basis: 0;
					flex-shrink: 0;
					display: flex;
					align-items: center;
					justify-content: flex-end;
				}

				.loader {
					flex-basis: 20px;
					flex-shrink: 0;
				}

				.error-icon {
					color: #d00;
				}

				.minimize-filter {
					flex-basis: 24px;
					line-height: 30px;
					flex-shrink: 0;
					text-align: center;
					cursor: pointer;
					font-size: 16px;
					color: #8ac;

					&:hover {
						color: #0af;
					}

					svg.minimized {
						transform: rotate(180deg);
					}
				}
			}

			.settings {
				padding: 10px 5px 10px 10px;

				&.minimized {
					display: none;
				}

				& > div {
					margin-bottom: 5px;
				}

				label {
					display: block;
					font-size: 11px;
				}
			}
		}
	}

	.colors-test {
		width: 100%;
		display: flex;
		align-items: center;

		.color {
			font-size: 11px;
			font-family: sans-serif;
			display: grid;
			place-items: center;
			width: 30px;
			height: 30px;
			background: #888;
			color: #fff;
			text-align: center;
			margin-right: 2px;
		}
	}

	.select-palette .palette-btn {
		min-width: 140px;
	}

	.silam-palettes {
		display: flex;
		padding: 1px;
		border: 1px solid #ddd;
		background: #fff;

		& > img {
			width: 40px;
			height: 200px;
			cursor: pointer;
			margin-right: 1px;

			&:last-child {
				margin-right: 0;
			}
		}
	}

	.radio-select {
		line-height: 20px;

		input[type=radio] {
			display: inline-block;
			margin-bottom: 2px;
			margin-right: 5px;
		}
	}

	.filter-hint {
		margin-left: 10px;
		color: #0af;
		cursor: pointer;
	}

	.filter-hint-popup {
		font-size: 12px;
		line-height: 1.4em;
		max-width: 300px;
	}

	.sub-select {
		padding-left: 17px;
		padding-top: 2px;
	}

	.heat-points-period {
		.sp-date-picker {
			min-width: 100px;

			&.start {
				margin-right: 10px;
			}
		}
	}

	.arr-btn {
		font-size: 18px;
		vertical-align: middle;

		& + & {
			margin-left: 10px;
		}
	}

	.map-additional-controls {
		position: absolute;
		top: 25px;
		left: 60px;
		height: 30px;
		width: 100px;
		z-index: var(--panel-z);
		display: flex;
		gap: 5px;

		&.shifted {
			left: 335px;
			top: 0;
		}

		.map-additional-btn {
			width: 30px;
			height: 30px;
			display: grid;
			background: #fff;
			border: 1px solid rgba(#000, 0.25);
			box-shadow: 1px 3px 3px rgba(#000, 0.15);
			border-radius: 4px;
			cursor: pointer;
			place-items: center;
			color: var(--primary);
			font-size: 16px;

			&.private {
				top: calc(var(--header-height) + 10px);
				left: calc(var(--sidebar-width) + 10px);
			}

			&:hover {
				color: #000;
			}
		}
	}

	.hp-legend {
		display: inline-block;
		width: 8px;
		height: 8px;
		background: #ccc;
		border-radius: 50%;

		&.today {
			background: #d00;
		}

		&.yesterday {
			background: #f80;
		}

		&.earlier {
			background: #aaa;
		}
	}
}

/* Черный фон, белый текст */
html.color-inversion{
	.map-filters, .filters-minimized{
		@include inversion-color-with-border;
	}
}