@import "site/styles/mixins";
@import "site/styles/mediaBreakpoints";

.MetaInfo{
  width: 100%;
  padding: 16px 0px;

  &-content{
    display: flex;
    gap: 6px;
    align-items: center;
    max-width: max-content;
    padding: 8px;
    border-radius: 4px;
    background: var(--grey-6);

    &-item{
      @include P3;
      display: flex;
      gap: 8px;
      align-items: center;
      color: var(--grey-1);
    }

    .delimiter{
      width: 1px;
      height: 16px;
      background: var(--black);
    }
  }
}

@media(max-width: $mobile-width-breakpoint){
  .MetaInfo{
    padding: 16px;

    &-content{
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      background: none;
      padding: 0;

      .delimiter{
        display: none;
      }
    }
  }
}